<template>
  <div class="jazeMainPanel">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
          <li @click="selectedTab = 'franchisePackageSettings'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'franchisePackageSettings'}">
            <span>Package Settings</span>
          </li>
          <li @click="selectedTab = 'defaultPackageSettings'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'defaultPackageSettings'}">
            <span>Default Package Settings</span>
          </li>
        </ul>
        <div v-show="selectedTab == 'franchisePackageSettings'">
          <form ref="franchiseForm" id="franchiseForm" class="appForm appForm--cozy is-validation-hidden">
            <div class="unifiSettingsHeader">
              <div class="unifiSettingsHeader__title">Choose Work-flow</div>
            </div>
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection">
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Workflow</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <select name="workFlow" class="workFlow" v-model="franchiseFlow">
                      <option value="1" v-if="franchiseSettingsDetails.edition='isp'">Cash Collected By Top Zone</option>
                      <option value="1" v-else>Cash Collected By Top Account</option>
                      <option value="2" v-if="franchiseSettingsDetails.edition='isp'">Cash Collected By Sub Zone</option>
                      <option value="2" v-else>Cash Collected By Sub Account</option>
                    </select>
                    <div class="jazeAlert jazeAlert--info">
                      <b>Work Flow Note :</b>
                      <span v-if="franchiseFlow=='1'">The end user will be paying the top account directly. The commission to the sub accounts will be given to the sub accounts by their respective top accounts based on their revenue sharing settings.</span>
                      <span v-else-if="franchiseFlow=='2'">The end user will be paying to their respective accounts. The cash collected will be paid to their top accounts by deducting their respective commission alone.</span>
                    </div>
                  </div>
                </div>
                <div class="unifiSettingsHeader">
                  <div class="unifiSettingsHeader__title">Enforce Policies</div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enforce Captive Portal</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enforceCaptivePortalForFranchise" id="enforceCaptivePortal" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enforceCaptivePortal!=undefined && franchiseSettingsDetails.enforceCaptivePortal == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enforce Package Policies</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enforceProfilePolicyForFranchise" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enforceProfilePolicyFranchise!=undefined && franchiseSettingsDetails.enforceProfilePolicyFranchise == 'yes' ? true : false"  @change="byDefaultPackageSwitch" /> &emsp;
                  </div>
                </div>
                <div class="appRow" v-show="showPackageHidden">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">By Default </label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="enforceProfilesForFranchiseVisible" value="showAllPackages" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enforceProfilesForFranchiseVisible !=undefined && franchiseSettingsDetails.enforceProfilesForFranchiseVisible == 'showAllPackages' ? true : false" />
                        <label for="revenueCalculationBasedOn"> Show all packages</label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="enforceProfilesForFranchiseVisible" value="dontShowAllPackages" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enforceProfilesForFranchiseVisible!=undefined && franchiseSettingsDetails.enforceProfilesForFranchiseVisible == 'dontShowAllPackages' ? true : false" />
                        <label for="revenueCalculationBasedOn">Don't show all packages</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unifiSettingsHeader">
                  <div class="unifiSettingsHeader__title">Revenue Settings</div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Revenue Calculated Based on</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="revenueCalculationBasedOn" value="invoice" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.revenueCalculationBasedOn!=undefined && franchiseSettingsDetails.revenueCalculationBasedOn == 'invoice' ? true : false" @click="showHiden=false"/>
                        <label for="revenueCalculationBasedOn">Invoice Generated (Including Tax)</label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="revenueCalculationBasedOn" value="invoiceWithoutTax" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.revenueCalculationBasedOn!=undefined && franchiseSettingsDetails.revenueCalculationBasedOn == 'invoiceWithoutTax' ? true : false" @click="toggleShowfn"/>
                        <label for="revenueCalculationBasedOn">Invoice Generated (Excluding Tax)</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showHiden">
                  <div class="appRow">
                    <div class="col--sm4">
                      <label class="appLabel appLabel--primary appLabel--boxAlign">Tax Bearable by TopAccount</label>
                    </div>
                    <div class="col--sm8 appFormGroup appFormGroup--cozy">
                      <input type="checkbox" name="franchiseAddServiceTax" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.addServiceTax!=undefined && franchiseSettingsDetails.addServiceTax == 'yes' ? true : false" /> &emsp;
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Additional Tax if any</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <button @click="additionalTaxAddModal=true" class="jazeButton jazeButton--primary" type="button" title="Add Additional Tax">
                      <span class="jaze-icon--plus"></span> &nbsp;Add
                    </button> &emsp;
                  </div>
                </div>
                <div v-if="franchiseFlowArr.length>0">
                  <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                    <thead>
                      <tr>
                        <th>
                          <span>Tax Name</span>
                        </th>
                        <th>
                          <span>Tax Percentage</span>
                        </th>
                        <th>
                          <span>Action</span>
                        </th>
                        <th></th>
                        <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                      </tr>
                    </thead>
                    <tbody class="jazeTableBody">
                      <tr v-if="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.additionalTaxes!=undefined &&franchiseSettingsDetails.additionalTaxes.length!=0" v-for="(additionalTax,index) in franchiseFlowArr">
                        <td>
                          <span>{{additionalTax.name}}</span>
                        </td>
                        <td>
                          <span>{{additionalTax.val}}</span>
                        </td>
                        <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                          <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                            <div class="jazeButtonGroup">
                              <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteAdditionalTaxes(additionalTax,index)">
                                <span class="button__icon icon jaze-icon--trashcan"></span>
                                <span class="button__label">Delete</span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Additional Tax Calculation from sharing</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="franchiseTaxType" value="yes" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseTaxType!=undefined && franchiseSettingsDetails.franchiseTaxType == 'yes' ? true : false" />
                        <label for="franchiseTaxType">Combine</label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="franchiseTaxType" value="no" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseTaxType!=undefined && franchiseSettingsDetails.franchiseTaxType == 'no' ? true : false" />
                        <label for="franchiseTaxType">Seperate</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">TDS</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="number" name="franchiseTdsValue" v-model="franchiseTdsValue" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Additional Tax Bearable by</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="franchiseAddAdditionalTax" value="yes" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseAddAdditionalTax!=undefined && franchiseSettingsDetails.franchiseAddAdditionalTax == 'yes' ? true : false" />
                        <label for="franchiseAddAdditionalTax">Top Zone</label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="franchiseAddAdditionalTax" value="no" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseAddAdditionalTax!=undefined && franchiseSettingsDetails.franchiseAddAdditionalTax == 'no' ? true : false" />
                        <label for="franchiseAddAdditionalTax">Franchise</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Payments Exclude from Invoice</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="paymentExcludeFromInvoice" value="donothing" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.paymentExcludeFromInvoice!=undefined && franchiseSettingsDetails.paymentExcludeFromInvoice == 'donothing' ? true : false" />
                        <label for="paymentExcludeFromInvoice">Do Nothing</label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="paymentExcludeFromInvoice" value="franchise" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.paymentExcludeFromInvoice!=undefined && franchiseSettingsDetails.paymentExcludeFromInvoice == 'franchise' ? true : false" />
                        <label for="paymentExcludeFromInvoice">Bearable By Franchise</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Revenue logs active days</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="text" name="revenueLogActivedays" v-model="revenueLogActivedays" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Require Approval For Payments?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="requireApprovalForFranchise" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.requireApproval == 'on' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Caluclate AGR Separately on Fixed Price (Separate) </label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="additionalTaxSeparateOnFixedPrice" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.additionalTaxSeparateOnFixedPrice!=undefined && franchiseSettingsDetails.additionalTaxSeparateOnFixedPrice == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow" v-if="franchiseFlow==1" no-display>
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Tax</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="number" name="tax" v-model="franchiseTax" />
                  </div>
                </div>
                <div class="unifiSettingsHeader">
                  <div class="unifiSettingsHeader__title">Additional Franchise Settings</div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Use override franchise share ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseUseOverrideFranchiseShare" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseUseOverrideFranchiseShare!=undefined && franchiseSettingsDetails.franchiseUseOverrideFranchiseShare == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Use override share percentage ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseUseSharePercentage" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseUseSharePercentage!=undefined && franchiseSettingsDetails.franchiseUseSharePercentage == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Franchise override end user price ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseOverrideEndUserPrice" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseAllowOverrideEndUserPrice!=undefined && franchiseSettingsDetails.franchiseAllowOverrideEndUserPrice == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Franchise Override Share Inverse</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseOverrideShareInverse" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseOverrideShareInverse!=undefined && franchiseSettingsDetails.franchiseOverrideShareInverse == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Original Franchise Override Share Price</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="useOrginalfranchiseOverrideSharePrice" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.useOrginalfranchiseOverrideSharePrice!=undefined && franchiseSettingsDetails.useOrginalfranchiseOverrideSharePrice == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Reset Credit Limit</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="resetCreditLimit" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.resetCreditLimit!=undefined && franchiseSettingsDetails.resetCreditLimit == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Hide Add Limit ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="hideAddLimit" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.hideAddLimit!=undefined && franchiseSettingsDetails.hideAddLimit == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Do not allow different User group and Package names ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseDifferentUserGroupandPackageName" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseDifferentUserGroupandPackageName!=undefined && franchiseSettingsDetails.franchiseDifferentUserGroupandPackageName == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Reduce Limit ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="reduceLimit" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.reduceLimit!=undefined && franchiseSettingsDetails.reduceLimit == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Reduce Commission account share from Sub-Zone ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseCommissionAccountShareFromSubzone" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseCommissionAccountShareFromSubzone!=undefined && franchiseSettingsDetails.franchiseCommissionAccountShareFromSubzone == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Add Commission share to balance ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseAddCommissionShareToBalance" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseAddCommissionShareToBalance!=undefined && franchiseSettingsDetails.franchiseAddCommissionShareToBalance == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Allow commission share to be negative ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseAllowSharePriceToNegative" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseAllowSharePriceToNegative!=undefined && franchiseSettingsDetails.franchiseAllowSharePriceToNegative == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Add intermediate franchise share to limit ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseAddShareToBalance" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseAddShareToBalance!=undefined && franchiseSettingsDetails.franchiseAddShareToBalance == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Do not allow Top-zone admin to recharge if franchise doesn't have balance ?</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseTopZoneAdminRestriction" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.franchiseTopZoneAdminRestriction!=undefined && franchiseSettingsDetails.franchiseTopZoneAdminRestriction == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Temporary Add Limit</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableFranchiseAddLimit" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enableFranchiseAddLimit!=undefined && franchiseSettingsDetails.enableFranchiseAddLimit == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Show Subzone Package Config</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="subzonePackageVisible" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.subzonePackageVisible!=undefined && franchiseSettingsDetails.subzonePackageVisible == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Reseller Account Billing</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableResellerBilling" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enableResellerBilling!=undefined && franchiseSettingsDetails.enableResellerBilling == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Separate Sharing For IPTV,OTT,Voice</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableChoosePackageTable"  @click="togglePackageTable" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enableChoosePackageTable!=undefined && franchiseSettingsDetails.enableChoosePackageTable == 'yes' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow" v-if="packageTableShow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Separate Sharing Applicable For</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <treeselect class="width-full workFlow" v-model="packageTable" :multiple="true" :options="packageTableOption" :clearable="true" :disable-branch-nodes="true" />
                </div>
              </div>
              <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Full Share To Top Account On Create Billing</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox"  name="fullShareToTopAccountOnAddUser" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.fullShareToTopAccountOnAddUser!=undefined && franchiseSettingsDetails.fullShareToTopAccountOnAddUser == 'yes' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Set Add Limit Validity</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox"  name="setValidityAddLimit" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.setValidityAddLimit!=undefined && franchiseSettingsDetails.setValidityAddLimit == 'yes' ? true : false" @click="togglevalidity" /> &emsp;
                  </div>
                </div>

                <div v-if="setvalidityShow" class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Set Add Limit Validity Based On : </label>
                  </div>
                  <!-- <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="radio" name="validitybasedOn" value="days" />
                    No of days &nbsp;&nbsp;
                  </div>                   -->
                  <div>
                    <!-- <div class="col--sm7"></div> -->
                    <div class="col--sm1 appFormGroup appFormGroup--cozy">
                      <input name="setvalidityperiod" v-model="setvalidityperiod" class="form-control input--full" type="number" min="1" />
                    </div>
                    <div class="col--sm2 appFormGroup appFormGroup--cozy">
                      <select class="form-control select--full" name="setvaliditytype" v-model="setvaliditytype">
                        <option value="days">Days</option>
                        <option value="weeks">Weeks</option>
                        <option value="months">Months</option>
                      </select>
                    </div>
                  </div>
                </div>



                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Generate Franchise Invoice on Add Limit Through Gateway</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox"  name="generateFranchiseInvoiceAutomatically" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.generateFranchiseInvoiceAutomatically!=undefined && franchiseSettingsDetails.generateFranchiseInvoiceAutomatically == 'yes' ? true : false" @change="toggleFranchiseInvoice" /> &emsp;
                  </div>
                </div>

                <div v-if="generateFranchiseInvoiceShow" class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Invoice Product Name </label>
                  </div>
                  <div>
                    <div class="col--sm8 appFormGroup appFormGroup--cozy">
                      <input name="generateFranchiseInvoiceProduct" v-model="generateFranchiseInvoiceProduct" class="form-control input--full" type="text"/>
                    </div>
                  </div>
                </div>

                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Specific amount deduction for E-KYC</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox"  name="enableEkycDeduction" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enableEkycDeduction!=undefined && franchiseSettingsDetails.enableEkycDeduction == 'yes' ? true : false" @change="toggleEnableEkycDeduction" /> &emsp;
                  </div>
                </div>

                <div v-if="enableEkycDeductionShow" class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Deduction amount for E-KYC </label>
                  </div>
                  <div>
                    <div class="col--sm8 appFormGroup appFormGroup--cozy">
                      <input name="ekycDeductionAmount" v-model="ekycDeductionAmount" type="number"/>
                    </div>
                  </div>
                </div>

                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Specific amount deduction for E-CAF</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox"  name="enableEcafDeduction" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.enableEcafDeduction!=undefined && franchiseSettingsDetails.enableEcafDeduction == 'yes' ? true : false" @change="toggleEnableEcafDeduction" /> &emsp;
                  </div>
                </div>

                <div v-if="enableEcafDeductionShow" class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Deduction amount for E-CAF </label>
                  </div>
                  <div>
                    <div class="col--sm8 appFormGroup appFormGroup--cozy">
                      <input name="ecafDeductionAmount" v-model="ecafDeductionAmount" type="number"/>
                    </div>
                  </div>
                </div>

                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Allow 4 level Commission Sharing</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox"  name="allowFourLevelCommission" :checked="franchiseSettingsDetails!=undefined && franchiseSettingsDetails.allowFourLevelCommission!=undefined && franchiseSettingsDetails.allowFourLevelCommission == 'yes' ? true : false" @change="toggleFourLevelCommission" /> &emsp;
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div v-show="selectedTab == 'defaultPackageSettings'">
          <DefaultPackageSettings v-if="selectedTab == 'defaultPackageSettings'" :selectedTab = selectedTab></DefaultPackageSettings>
        </div>

        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteConfirmModal" style="display:flex">
          <div class="pos-relative" dialog-window>
            <div dialog-content>
              <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
                <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
                  <div class="jazeModal__header pb-0">
                    <div class="jazeHeader jazeHeader--centered">
                      <div class="jazeHeader__title">Delete Additional Tax</div>
                      <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteConfirmModal=false">
                        <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                          <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div class="jazeModal__main">
                    <div class="jazeModalContent pb-1">
                      <div class="container container--horizontal-cozy container--vertical-comfy">
                        <div class="row">
                          <div class="col jazeFormGroup jazeFormGroup--flex">
                            <label class="jazeLabel jazeLabel--primary">Are you sure you want to delete this Additional Tax ?</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="jazeModal__footer">
                    <div class="jazeButtonGroup jazeButtonGroup--cozy">
                      <button class="jazeButton jazeButton--secondary" type="button" @click="deleteConfirmModal=false">Cancel</button>
                      <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deleteTaxConfirm">Delete</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="additionalTaxAddModal" style="display:flex">
          <div class="pos-relative" dialog-window>
            <div dialog-content>
              <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
                <div ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
                  <div class="jazeModal__header pb-0">
                    <div class="jazeHeader jazeHeader--centered">
                      <div class="jazeHeader__title">Add Tax</div>
                      <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="additionalTaxAddModal=false">
                        <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                          <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div class="jazeAlert jazeAlert--danger row col-12" v-show="errorShow">
                    <div class="col-6">
                      <strong>{{errorMessage}}</strong>
                    </div>
                  </div>
                  <div class="jazeModal__main">
                    <div class="jazeModalContent pb-1">
                      <div class="container container--horizontal-cozy container--vertical-comfy">
                        <div class="row">
                          <form ref="addTaxForm" id="addTaxForm" class="col--sm12">
                            <div class="col jazeFormGroup">
                              <div class="appRow">
                                <div class="col--sm4">
                                  <label class="appLabel appLabel--boxInline">Tax Name</label>
                                </div>
                                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                                  <input type="text" class="input--full" name="taxName" v-model="taxName" required oninvalid="setCustomValidity(' ')" oninput="setCustomValidity('')" />
                                </div>
                              </div>
                              <div class="appRow">
                                <div class="col--sm4">
                                  <label class="appLabel appLabel--boxInline">Tax Percentage</label>
                                </div>
                                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                                  <input type="number" min="0" class="input--full" name="taxPercent" v-model="taxPercent" required oninvalid="setCustomValidity(' ')" oninput="setCustomValidity('')" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="jazeModal__footer">
                    <div class="jazeButtonGroup jazeButtonGroup--cozy">
                      <button class="jazeButton jazeButton--secondary" type="button" @click="additionalTaxAddModal=false">Cancel</button>
                      <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addNewTax">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="selectedTab == 'franchisePackageSettings'" class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveFranchiseSettings($event)">Save</button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage="successMessage" :sucesstoasterShow="successtoaster" v-if="successtoaster"></SuccessToaster>
    <ErrorToaster :errorMessage="errorMessage" :errortoasterShow="errortoaster" v-if="errortoaster"></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import DefaultPackageSettings from "@/components/Settings/DefaultPackageSettings.vue";
import Treeselect from "jaze-vue3-treeselect";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const deleteIndex = ref( "" )
  const franchiseFlow = ref( "1" )
  const setvaliditytype = ref( "days" )
  const setvalidityperiod = ref( "1" )
  // const franchiseSettingsDetails = ref( [] )
  const deleteConfirmModal = ref( false )
  const setValidityAddLimit1 = ref( "" )
  const setvalidityShow = ref( false )
  const errorShow = ref( false )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const errorMessage = ref( "" )
  const additionaltaxArr = ref( [] )
  const additionalTaxAddModal = ref( false )
  const franchiseTdsValue = ref( "0" )
  const revenueLogActivedays = ref( "0" )
  const franchiseTax = ref( "0" )
  const taxName = ref( "" )
  const taxPercent = ref( "" )
  const franchiseFlowArr = ref( [] )
  const valueTax = ref( "" )
  const nameTax = ref( "" )
  const taxCount = ref( "" )
  const formClass = ref( "" )
  const showHiden = ref( false )
  const generateFranchiseInvoiceShow = ref( false )
  const generateFranchiseInvoiceProduct = ref( "" )
  const enableEkycDeductionShow = ref(false)
  const ekycDeductionAmount = ref("")
  const enableEcafDeductionShow = ref(false)
  const ecafDeductionAmount = ref("")
  const selectedTab = ref( 'franchisePackageSettings' )
  const showPackageHidden = ref( false )
  const packageTableShow = ref( false )
  const packageTable = ref( [] )
  const allowFourLevelCommission = ref(false)
  const packageTableOption = ref( [
          {
            id: "All",
            label: "Select All",
            children: [
              {
                id: "OTT",
                label: "OTT",
              },
              {
                id: "IPTV",
                label: "IPTV",
              },
              {
                id: "Voice",
                label: "Voice",
              },
            ],
          },
        ] )

  const settingsStore = usesettingsStore()
  const { franchiseSettingsDetails } = storeToRefs(settingsStore)
  const franchiseSettingsGetter = computed(() => franchiseSettingsDetails.value)

  onMounted(() => {
    getfranchiseSettingsData();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    packageTable.value = [];
    franchiseSettingsDetails.value = franchiseSettingsGetter.value;
    franchiseTdsValue.value = franchiseSettingsDetails.value.franchiseTdsValue;
    packageTable.value = franchiseSettingsDetails.value.choosePackageData;
    revenueLogActivedays.value = franchiseSettingsDetails.value.revenueLogActivedays;
    franchiseTax.value = franchiseSettingsDetails.value.franchiseTax;
    taxName.value = franchiseSettingsDetails.value.taxName;
    taxPercent.value = franchiseSettingsDetails.value.taxPercent;
    franchiseFlow.value = franchiseSettingsDetails.value.franchiseFlow;
    var i = 1;
    if (franchiseSettingsDetails.value.additionalTaxes != undefined) {
      for (const [key, value] of Object.entries(
        franchiseSettingsDetails.value.additionalTaxes
      )) {
        if (franchiseSettingsDetails.value.additionalTaxes.count >= i) {
          var name = "franchiseAdditionalTax" + i;
          var val = "franchiseAdditionalTaxValue" + i;
          franchiseFlowArr.value.push({
            name: franchiseSettingsDetails.value.additionalTaxes[name],
            val: franchiseSettingsDetails.value.additionalTaxes[val],
            taxName: name,
            taxValue: val,
          });
        }
        i++;
      }
    }
    if(franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.enforceProfilePolicyFranchise!=undefined && franchiseSettingsDetails.value.enforceProfilePolicyFranchise == 'yes'){
      showPackageHidden.value = true;
    }
    if(franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.enableChoosePackageTable!=undefined && franchiseSettingsDetails.value.enableChoosePackageTable == 'yes'){
      packageTableShow.value = true;
    }
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.setValidityAddLimit!=undefined && franchiseSettingsDetails.value.setValidityAddLimit == "yes") {
      setvalidityShow.value = true;
    }
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.generateFranchiseInvoiceAutomatically!=undefined && franchiseSettingsDetails.value.generateFranchiseInvoiceAutomatically == "yes") {
      generateFranchiseInvoiceShow.value = true;
    }
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.generateFranchiseInvoiceProduct!=undefined &&  franchiseSettingsDetails.value.generateFranchiseInvoiceProduct != "") {
      generateFranchiseInvoiceProduct.value = franchiseSettingsDetails.value.generateFranchiseInvoiceProduct;
    }
    //E-KYC
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.enableEkycDeduction!=undefined && franchiseSettingsDetails.value.enableEkycDeduction == "yes") {
      enableEkycDeductionShow.value = true;
    }
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.ekycDeductionAmount!=undefined &&  franchiseSettingsDetails.value.ekycDeductionAmount != "") {
      ekycDeductionAmount.value = franchiseSettingsDetails.value.ekycDeductionAmount;
    }
    //E-CAF
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.enableEcafDeduction!=undefined && franchiseSettingsDetails.value.enableEcafDeduction == "yes") {
      enableEcafDeductionShow.value = true;
    }
    if (franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.ecafDeductionAmount!=undefined &&  franchiseSettingsDetails.value.ecafDeductionAmount != "") {
      ecafDeductionAmount.value = franchiseSettingsDetails.value.ecafDeductionAmount;
    }
    if (
      franchiseSettingsDetails.value.setValidityBasedOnDay != undefined &&
      franchiseSettingsDetails.value.setValidityBasedOnDay != "" &&
      franchiseSettingsDetails.value.setValidityBasedOnDay != null
    ) {
      setvalidityperiod.value =
        franchiseSettingsDetails.value.setValidityBasedOnDay;
    }
    if (
      franchiseSettingsDetails.value.setValidityBasedOnType != undefined &&
      franchiseSettingsDetails.value.setValidityBasedOnType != "" &&
      franchiseSettingsDetails.value.setValidityBasedOnType != null
    ) {
      setvaliditytype.value =
        franchiseSettingsDetails.value.setValidityBasedOnType;
    }
    if(franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.enforceProfilePolicyFranchise!=undefined && franchiseSettingsDetails.value.enforceProfilePolicyFranchise == 'yes'){
      showPackageHidden.value = true;
    }

      if(franchiseSettingsDetails.value!=undefined && franchiseSettingsDetails.value.revenueCalculationBasedOn!=undefined && franchiseSettingsDetails.value.revenueCalculationBasedOn == 'invoiceWithoutTax'){
        showHiden.value= true;
    }
  })

  const getfranchiseSettingsData = () => {
    if (Object.keys(franchiseSettingsGetter.value).length <= 1) {
      // $store.dispatch("fetchfranchiseSettingsData");
      settingsStore.fetchfranchiseSettingsData();
    } else {
      franchiseSettingsDetails.value = franchiseSettingsGetter.value;
    }
  }
  const byDefaultPackageSwitch = (event) => {
    if (event.target.checked == true) {
      showPackageHidden.value = true;
    } else {
      showPackageHidden.value = false;
    }
  }
  const toggleShowfn = (event) => {
    if(event.target.checked == true){
      showHiden.value=true;
    }else{
      showHiden.value=false;
    }
  }
  const addNewTax = (e) => {
    e.preventDefault();
    successtoaster.value = false;
    errortoaster.value = false;
    var form = document.getElementById('addTaxForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    var status = checkForm(postData);
    if (status == "error") {
      formClass.value = "is-form-validation-visible";
      return;
    }
    errorShow.value = false;
    // $store.dispatch("addNewTaxSubmit", postData).then(
    settingsStore.addNewTaxSubmit(postData).then(  
      (response) => {
        additionalTaxAddModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        var i = franchiseFlowArr.value.length + 1;
        var name = "franchiseAdditionalTax" + i;
        var val = "franchiseAdditionalTaxValue" + i;
        franchiseFlowArr.value.push({
          name: postData["taxName"],
          val: postData["taxPercent"],
          taxName: name,
          taxValue: val,
        });
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          5000
        );
      },
      (error) => {
        additionalTaxAddModal.value = false;
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          1500
        );
        console.log(error);
      }
    );
  }
  const checkForm = (postData) => {
    if (postData.taxName == "") {
      errorMessage.value = "Tax Name is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.taxPercent == "") {
      errorMessage.value = "Tax Value is Required";
      errorShow.value = true;
      return "error";
    }
  }
  const checkFormSubmit = (postData) => {
    if(generateFranchiseInvoiceShow.value && (postData.generateFranchiseInvoiceProduct =="" || postData.generateFranchiseInvoiceProduct == null)){
      errorMessage.value = "Invoice Product Name is Required";
      errorShow.value = true;
      return "error";
    }
    //E-KYC
    if(enableEkycDeductionShow.value && (postData.ekycDeductionAmount =="" || postData.ekycDeductionAmount == null)){
      errorMessage.value = "Deduction amount for E-KYC is Required";
      errorShow.value = true;
      return "error";
    }
    //E-CAF
    if(enableEcafDeductionShow.value && (postData.ecafDeductionAmount =="" || postData.ecafDeductionAmount == null)){
      errorMessage.value = "Deduction amount for E-CAF is Required";
      errorShow.value = true;
      return "error";
    }
  }
  const toggleFranchiseInvoice = (event) => {
    if (event.target.checked == true) {
      generateFranchiseInvoiceShow.value = true;
    } else {
      generateFranchiseInvoiceShow.value = false;
    }
  }
  const toggleEnableEkycDeduction = (event) => {
    if (event.target.checked == true) {
      enableEkycDeductionShow.value = true;
    } else {
      enableEkycDeductionShow.value = false;
    }
  }
  const toggleEnableEcafDeduction = (event) => {
    if (event.target.checked == true) {
      enableEcafDeductionShow.value = true;
    } else {
      enableEcafDeductionShow.value = false;
    }
  }
  const toggleFourLevelCommission = (event) => {
    if (event.target.checked == true) {
      allowFourLevelCommission.value = true;
    } else {
      allowFourLevelCommission.value = false;
    }
  }
  const togglevalidity = (event) => {
    if (event.target.checked == true) {
      setvalidityShow.value = true;
    } else {
      setvalidityShow.value = false;
    }
  }
  const togglePackageTable = (event) => {
    if (event.target.checked == true) {
      packageTableShow.value = true;
    } else {
      packageTableShow.value = false;
    }
  }
  const deleteAdditionalTaxes = (additionalTax, index) => {
    nameTax.value = additionalTax.taxName;
    valueTax.value = additionalTax.taxValue;
    taxCount.value = index + 1;
    deleteIndex.value = index;
    deleteConfirmModal.value = true;
  }
  const deleteTaxConfirm = () => {
    var data = {
      taxName: nameTax.value,
      taxValue: valueTax.value,
      count: taxCount.value,
    };
    // $store.dispatch("deleteAdditionalTaxesSubmit", data).then(
    settingsStore.deleteAdditionalTaxesSubmit(data).then(  
      (response) => {
        deleteConfirmModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        franchiseFlowArr.value.splice(deleteIndex.value, 1);
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          5000
        );
      },
      (error) => {
        deleteConfirmModal.value = false;
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          1500
        );
        console.log(error);
      }
    );
  }
  const saveFranchiseSettings = (e) => {
    successtoaster.value = false;
    errortoaster.value = false;
    e.preventDefault();
    errorShow.value = false;
    var form = document.getElementById('franchiseForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    postData.tax = franchiseTax.value;
    if (packageTable.value[0] == "All") {
      packageTable.value = [];
      packageTable.value = ["OTT", "IPTV", "Voice"];
    }
    postData.choosePackageTable = packageTable.value;
    if (postData.enforceCaptivePortalForFranchise == "on") {
      postData.enforceCaptivePortalForFranchise = "yes";
    }
    if (postData.enforceProfilePolicyForFranchise == "on") {
      postData.enforceProfilePolicyForFranchise = "yes";
    }
    if (postData.franchiseAddServiceTax == "on") {
      postData.franchiseAddServiceTax = "yes";
    }
    var status = checkFormSubmit(postData);
    if (status == "error") {
      errortoaster.value = true;
      return;
    }
    errortoaster.value = false;
    // $store.dispatch("franchiseSettingsSubmit", postData).then(
    settingsStore.franchiseSettingsSubmit(postData).then(  
      (response) => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }

</script>
