<template>
    <div>
        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
            <div class="pos-relative" dialog-window>
                <div dialog-content>
                <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
                    <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
                    <div class="jazeModal__header pb-0">
                        <div class="jazeHeader jazeHeader--centered">
                        <div class="jazeHeader__title"> Save </div>
                        <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="onClose">
                            <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                            <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                            </g>
                        </svg>
                        </div>
                    </div>
                    <div class="jazeModal__main">
                        <div class="jazeModalContent pb-1">
                        <div class="container container--horizontal-cozy container--vertical-comfy">
                            <div class="row">
                            <div class="col jazeFormGroup jazeFormGroup--flex">
                                <label class="jazeLabel jazeLabel--primary" v-if="edition =='isp'">Allow/Deny sub-zones to change {{prefixPageData.title_for_layout}} Configuration</label>
                                <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change {{prefixPageData.title_for_layout}} Configuration</label>&nbsp;
                                <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                                <input type="checkbox" :checked="prefixPageData.subAccountsCanNotChangePrefixConfiguration == 'Yes' ? 'checked' : ''" v-model="switchcheckboxTableHeader" id="switchcheckboxTableHeader" >
                                <label for="switchcheckboxTableHeader"></label>
                                </div>
                            </div>
                            </div>
                            <div class="appType--micro appType--quiet italicized" v-if="edition =='isp'">
                            Note: If it is 'ON', sub-zones can not change {{prefixPageData.title_for_layout}} Configuration.
                            </div>
                            <div class="appType--micro appType--quiet italicized" v-else>
                            Note: If it is 'ON', sub-accounts can not change {{prefixPageData.title_for_layout}} Configuration.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="jazeModal__footer">
                        <div class="jazeButtonGroup jazeButtonGroup--cozy">
                        <button class="jazeButton jazeButton--secondary" type="button" @click="onClose">Cancel</button>
                        <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref,toRefs,defineProps,defineEmits,onMounted } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import Treeselect from "jaze-vue3-treeselect";
import { usesettingsStore } from '@/store/settingsstore'

    const emit = defineEmits(['clicked-prefix-subzone-restrictions-detail']);
    const props = defineProps(["prefixTab', 'prefixPageData', 'edition"]);

    const { prefixTab, prefixPageData, edition } = toRefs(props)
    const formClass = ref( "" )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorShow = ref( false )
    const subZoneModalShow = ref( true )
    const switchcheckboxTableHeader = ref( false )

    const settingsStore = usesettingsStore()

    onMounted(() => {
        getData();
    })

    const getData = () => {
        if(prefixPageData.value.subAccountsCanNotChangePrefixConfiguration == 'Yes') {
            switchcheckboxTableHeader.value = true;
        }
    }
    const onClose = () => {
        subZoneModalShow.value = false;
        emit("clicked-prefix-subzone-restrictions-detail", subZoneModalShow.value);
    }
    const allowSubZones = () => {
        successtoaster.value = false;
        errortoaster.value = false;
        if (switchcheckboxTableHeader.value == true) {
        var check = "on";
        } else {
        var check = "off";
        }
        var prefix = null;
        if(prefixTab.value == 'invoicePrefix') {
            prefix = 'subAccountsCanNotChangeInvoicePrefixConfiguration';
        } else if(prefixTab.value == 'proformaPrefix') {
            prefix = 'subAccountsCanNotChangeProformaInvoicePrefixConfiguration';
        } else if(prefixTab.value == 'paymentPrefix') {
            prefix = 'subAccountsCanNotChangePaymentPrefixConfiguration';
        } else if(prefixTab.value == 'circuitPrefix') {
            prefix = 'subAccountsCanNotChangeCircuitPrefixConfiguration';
        } else if(prefixTab.value == 'leadPrefix') {
            prefix = 'subAccountsCanNotChangeLeadPrefixConfiguration';
        } else if(prefixTab.value == 'helpdeskPrefix') {
            prefix = 'subAccountsCanNotChangeHelpdeskPrefixConfiguration';
        } else if(prefixTab.value == 'creditNotePrefix') {
            prefix = 'subAccountsCanNotChangeCreditNotePrefixConfiguration';
        } else if(prefixTab.value == 'cafPrefix') {
            prefix = 'subAccountsCanNotChangeCafPrefixConfiguration';
        }
        var data = {
            switchCheckBoxValue: check,
            attribute: prefix,
            type: 'prefix'
        };
        // $store.dispatch("saveSubAccountPermissionSettings", data).then(
        settingsStore.saveSubAccountPermissionSettings(data).then(    
        response => {
            subZoneModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
            function() {
                successtoaster.value = false;
                emit("clicked-prefix-subzone-restrictions-detail", false);
            }.bind(this),
            4000
            );
        },
        error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
            function() {
                errortoaster.value = false;
                emit("clicked-prefix-subzone-restrictions-detail", false);
            }.bind(this),
            15000
            );
            console.log(error);
        }
        );
    }

</script>