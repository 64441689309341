<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="billingAddressForm" id="billingAddressForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Billing Address</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Address 1</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="address1" v-model="address1">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Address 2</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="address2" v-model="address2">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">City</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="city" v-model="city">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Pincode</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="postalCode" v-model="postalCode">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">State</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="stateProvince" v-model="stateProvince">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Phone</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="phoneNumber" v-model="phoneNumber">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Email</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type="text" name="email" v-model="email">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Country</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect name="countryCode" v-if="countryCode != undefined" v-model="countryCode" :options="countryOptions" :searchable="true" :clearable="false" placeholder="Search"></treeselect>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">GST Number</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type= "text" name="gstNumber" v-model="gstNumber">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">PAN Number</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type= "text" name="panNumber" v-model="panNumber">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Auto Tax Selection of CGST,SGST and IGST</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full " type= "checkbox" name="autoTaxSelection" :checked="autoTaxSelection!=undefined && autoTaxSelection == 'yes' ? 'checked' : ''">
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="addressData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-if="addressData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="addressData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>
            
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveBillingAddress"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex col-12">
                        <label class="jazeLabel jazeLabel--primary col-8" for="notifyViaEmail" v-if="addressData.edition =='isp'">Allow/Deny sub-zones to change billing address</label>
                        <label class="jazeLabel jazeLabel--primary col-8" for="notifyViaEmail" v-else>Allow/Deny sub-accounts to change billing address</label>
                        <div class="col-1"> </div>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxBillingAdd" id="switchcheckboxBillingAdd" :checked="addressData.subAccountsCanNotChangeBillingAddress == 'Yes' ? 'checked' : ''">
                          <label for="switchcheckboxBillingAdd"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="addressData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change the billing address.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change the billing address.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="addressData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'billingAddressSetting'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import { uselayoutStore } from '@/store/layoutstore'

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails,bankCurrencyCountryListData } = storeToRefs(layoutStore)
  const bankCurrencyCountryListGetter = computed(() => bankCurrencyCountryListData.value)
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  const addressData = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const address1 = ref( "" )
  const address2 = ref( "" )
  const city = ref( "" )
  const postalCode = ref( "" )
  const stateProvince = ref( "" )
  const phoneNumber  = ref( "" )
  const email = ref( "" )
  const countryCode = ref( null)
  const gstNumber  = ref( null )
  const panNumber  = ref( null )
  const countryOptions = ref( [] )
  const subzoneType = ref( "" )
  const subZoneModalShow = ref( false )
  const selectedAccVal = ref( null )
  const autoTaxSelection = ref( 'no' )
  const subAccOptions = ref( [{
            id: 'selectAll',
            label: 'Select All',
            children: []
        }] )
  const switchcheckboxBillingAdd = ref( false )
  const optionaAllSelect = ref( "all" )
  const dataSetting = ref( [{
            id: "selectAll",
            label: "Select All",
            children: []
          }] )
  const pushModal = ref( false )
  const selected = ref( [] )
  const subAccounts = ref( [] )

  const settingsStore = usesettingsStore()
  const { billingAddress } = storeToRefs(settingsStore)
  const billingAddressGetter = computed(() => billingAddress.value);

  onMounted(() => {
    if(bankCurrencyCountryListGetter.value == undefined || (bankCurrencyCountryListGetter.value != undefined && Object.keys(bankCurrencyCountryListGetter.value).length == 0) ||
      (Object.keys(bankCurrencyCountryListGetter.value).length > 0 && (bankCurrencyCountryListGetter.value.countriesLists == undefined || (bankCurrencyCountryListGetter.value.countriesLists != undefined && bankCurrencyCountryListGetter.value.countriesLists.length == 0)))){
      layoutStore.fetchBankCurrencyCountryDetails(["countriesLists"]);
    }
    getAddress();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    addressData.value = billingAddressGetter.value;
    if(addressData.value.accountsArr!=undefined && addressData.value.accountsArr.length !=0) {
        for(let i=0; i<addressData.value.accountsArr.length; i++) {
          var deactivated = addressData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
            subAccOptions.value[0].children.push({id:addressData.value.accountsArr[i].id, label:addressData.value.accountsArr[i].name + deactivated,})
        }
    }
    autoTaxSelection.value = addressData.value.autoTaxSelection;
    if (
      addressData.value.dataSetting != undefined &&
      addressData.value.dataSetting.length != 0
    ) {
      for (let i = 0; i < addressData.value.dataSetting.length; i++) {
        dataSetting.value[0].children.push({
          id: addressData.value.dataSetting[i].id,
          label: addressData.value.dataSetting[i].label,
        });
      }
    }
    if(addressData.value.accountDetails!=undefined && Object.keys(addressData.value.accountDetails).length != 0) {
      if(addressData.value.accountDetails.AccountInfo.street_address1 == 'accountid') {
          address1.value = "";
      } else {
          address1.value = addressData.value.accountDetails.AccountInfo.street_address1;
      }
      if(addressData.value.accountDetails.AccountInfo.street_address2 == 'from cloud') {
          address2.value = "";
      } else {
          address2.value = addressData.value.accountDetails.AccountInfo.street_address2;
      }
      if(addressData.value.accountDetails.AccountInfo.street_address2 == 'from cloud' && addressData.value.accountDetails.AccountInfo.city == "Chennai") {
          city.value = "";
      } else {
          city.value = addressData.value.accountDetails.AccountInfo.city;
      }
      if(addressData.value.accountDetails.AccountInfo.street_address2 == 'from cloud' && addressData.value.accountDetails.AccountInfo.city == "Chennai" && addressData.value.accountDetails.AccountInfo.postal_code == "600097") {
          postalCode.value = "";
      } else {
          postalCode.value = addressData.value.accountDetails.AccountInfo.postal_code;
      }
      if(addressData.value.accountDetails.AccountInfo.street_address2 == 'from cloud' && addressData.value.accountDetails.AccountInfo.city == "Chennai" && addressData.value.accountDetails.AccountInfo.state_province == "TamilNadu") {
          stateProvince.value = "";
      } else {
          stateProvince.value = addressData.value.accountDetails.AccountInfo.state_province;
      }
      if(addressData.value.accountDetails.AccountInfo.phone == '33185100') {
          phoneNumber.value = "";
      } else {
          phoneNumber.value = addressData.value.accountDetails.AccountInfo.phone;
      }
      if(addressData.value.accountDetails.AccountInfo.email == 'contact@jazenetworks.com') {
          email.value = "";
      } else {
          email.value = addressData.value.accountDetails.AccountInfo.email;
      }
      
      if(addressData.value.accountDetails.AccountInfo.pan_number == '' || addressData.value.accountDetails.AccountInfo.pan_number == null || addressData.value.accountDetails.AccountInfo.pan_number == undefined) {
          panNumber.value = "";
      } else {
          panNumber.value = addressData.value.accountDetails.AccountInfo.pan_number;
      }
      if(addressData.value.accountDetails.AccountInfo.gst_number == '' || addressData.value.accountDetails.AccountInfo.gst_number == null || addressData.value.accountDetails.AccountInfo.gst_number == undefined) {
          gstNumber.value = "";
      } else {
          gstNumber.value = addressData.value.accountDetails.AccountInfo.gst_number;
      }
      if(bankCurrencyCountryListGetter.value != undefined && bankCurrencyCountryListGetter.value.countriesLists != undefined && Object.keys(bankCurrencyCountryListGetter.value.countriesLists).length != 0) {
        for (const [key, name] of Object.entries(bankCurrencyCountryListGetter.value.countriesLists)) {
          countryOptions.value.push({'id': key, 'label':name.country_name})
        }
      }
      countryCode.value = addressData.value.accountDetails.AccountInfo.country_code;

      if(addressData.value.subAccountsCanNotChangeBillingAddress == 'Yes') {
        switchcheckboxBillingAdd.value = true;
      }
    }
  })

  const getAddress = () => {
    if (Object.keys(billingAddressGetter.value).length == 0) {
      // $store.dispatch("fetchBillingAddress");
      settingsStore.fetchBillingAddress();
    } else {
      addressData.value = billingAddressGetter.value;
    }
  }
  const saveBillingAddress = (e) => {
    e.preventDefault();
    successtoaster.value = false;
    errortoaster.value = false;
    errorShow.value = false;
    var form = document.getElementById('billingAddressForm');
    const formData = new FormData(form); // reference to form element
    const postData = {}; // need to convert it before using not with XMLHttpRequest
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    // $store.dispatch("billingAddressSubmit", postData).then(
    settingsStore.billingAddressSubmit(postData).then(  
      response => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxBillingAdd.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeBillingAddress",
      type: 'billing_addr'
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      response => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const onclickclose = (value) => {
    pushModal.value = value;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

</script>
