<template>
  <div>
    <div class="jazePanelContainer jazePanelContainer--viewport jazePanelContainer--horizontal">
     
       
                  
      <div class="jazeMainPanel">
        <div class="jazePanelContainer jazePanelContainer--withSidePanel">
          <div class="jazeMainPanel">
            <div class="jazePanelContainer jazePanelContainer--withHeaderPanel">
              <div class="jazeMainPanel" id="contentContainer">
                <div class="jazePanelContainer jazePanelContainer--withSidePanel ">
                  <div :class="isSidebar">
                   <jazeHeaderError v-if='headerWarnDetails != ""'></jazeHeaderError>
      <jazeHeader @extend-mobile-show-detail="mobileShowSet" v-if='headerWarnDetails != ""'></jazeHeader> 
                    <jazeHeaderMenu :mobileShow="mobileShow" v-if='headerWarnDetails != "" && mobileMenu && !incompleteSubZone && !licenseStatus'></jazeHeaderMenu>
                    <slot v-if='headerWarnDetails != "" && isSidebar != "jazeMainPanel"'></slot>
                    <SettingsBar v-if='isSidebar == "jazeMainPanel"'></SettingsBar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import jazeHeader from "@/components/layout/Header.vue";
import jazeHeaderError from "@/components/layout/HeaderError.vue";
import jazeHeaderMenu from "@/components/layout/HeaderMenu.vue";
import SettingsBar from "@/views/settings/Settings.vue";

import { uselayoutStore } from '@/store/layoutstore'
import {storeToRefs} from "pinia";
import { ref,computed,watchEffect,onMounted } from 'vue'
import { useRouter,useRoute } from 'vue-router';

  const router = useRouter();
  const route = useRoute();
  const layoutStore = uselayoutStore()
  const { headerDetails } = storeToRefs(layoutStore)   
  const mobileMenu=ref(false)
  const mobileOrDesktop=ref("")
  const mobileShow=ref("");
  const headerWarnDetails=ref([]);
  const isSidebar=ref(route.path.startsWith("/settings/")
    ? "jazeMainPanel"
    : "jazePanelContent jazePanelContent--scrollable");
  const headerDetailsGettera= computed(() =>headerDetails.value);
  watchEffect(() => {
    isSidebar.value=route.path.startsWith("/settings/")
    ? "jazeMainPanel"
    : "jazePanelContent jazePanelContent--scrollable";
    headerWarnDetails.value=headerDetailsGettera.value;       
    if (
    Object.keys(headerDetailsGettera.value).length > 0 &&
    typeof headerDetailsGettera.value.Auth.Dashboard != "undefined" &&
    headerWarnDetails.value.Auth.Dashboard.MobileOrDesktopActual != "mobile"
    ) {
      mobileMenu.value = true;
    }
    }
  );
  const getHeaderDetails = () => {
    if (Object.keys(headerDetailsGettera.value).length == 0) {
      layoutStore.fetchHeaderDetails()
    } else {
      headerDetails.value = headerDetailsGettera.value;
    }
  }
  const mobileShowSet = (value) => {
    if (value == "mobileShow") {
      mobileMenu.value = true;
    } else if (value == "no") {
      mobileMenu.value = false;
    } else {
      mobileMenu.value = true;
    }
    mobileOrDesktop.value = "mobile";
    mobileShow.value = value;
  }
  onMounted(() => {
    getHeaderDetails()
  })
  const incompleteSubZone= computed(() =>{
    var incompleteSubZone = false;
    if (
      Object.keys(headerDetailsGettera.value).length > 0 &&
      typeof headerDetailsGettera.value.Auth.Dashboard != "undefined" &&
      headerDetailsGettera.value.Auth.Dashboard.accountcreationStep == "yes"
    ) {
      incompleteSubZone = true;
      if (route.path.indexOf("create_subaccount") == -1) {
        router.push("/dashboard/create_subaccount");
      }
    }
    return incompleteSubZone;
  });
  const licenseStatus= computed(() =>{
    var licenseStatus1 = false;
    if (
      Object.keys(headerDetailsGettera.value).length > 0 &&
      typeof headerDetailsGettera.value.licenseStatusCheck != "undefined"
    ) {
      var licenseStatus = headerDetailsGettera.value.licenseStatusCheck;
      if (licenseStatus == "trialExpired") {
        licenseStatus1 = true;
        router.push("/license/index");
      }
    }
    return licenseStatus1;
  })

</script>