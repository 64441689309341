<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="miscellConfigForm" id="miscellConfigForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Miscellaneous Configuration</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Update Bandwidth Cycle Based on User Created Date </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="updateBandwidthCycleBasedOnCreatedDate" :checked="miscellConfigDetails.updateBandwidthCycleBasedOnCreatedDate==true ? true : false" @click="showBandwidthCycle= !showBandwidthCycle"> &emsp;
                  <select name="updateBandwidthCycleBasedOnCreatedDatePeriod" v-if="showBandwidthCycle==true" v-model="bandwidthCycle">
                    <option value="monthly"> monthly </option>
                    <option value="weekly"> weekly </option>
                    <option value="daily"> daily </option>
                  </select>
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Update Vendor Cycle Based on User Created Date </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="updateVendorCycleBasedOnCreatedDate" :checked="miscellConfigDetails.updateVendorCycleBasedOnCreatedDate==true ? true : false" @click="showVendorCycle= !showVendorCycle"> &emsp;
                  <select name="updateVendorCycleBasedOnCreatedDatePeriod" v-if="showVendorCycle==true" v-model="vendorCycle">
                    <option value="monthly"> monthly </option>
                    <option value="weekly"> weekly </option>
                    <option value="daily"> daily </option>
                  </select>
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Send SMS to Alternate Number </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" :checked="miscellConfigDetails.sendSMSAlternative!=undefined && miscellConfigDetails.sendSMSAlternative == 'yes' ? true : false" name="sendSMSAlternative">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Send Email to Alternate Email </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" :checked="miscellConfigDetails.sendEmailAlternative!=undefined && miscellConfigDetails.sendEmailAlternative == 'yes' ? true : false" name="sendEmailAlternative">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Automatically add simultaneous Devices </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="addSimultaneousDevices" :checked="miscellConfigDetails.addSimultaneousDevices!=undefined && miscellConfigDetails.addSimultaneousDevices == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> If MAC address is not specified for the user, then permit login? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="macSpecifyPermitLoginEnable" :checked="miscellConfigDetails.macSpecifyPermitLoginEnable == true ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> If IP address is not specified for the user, then permit login? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="ipSpecifyPermitLoginEnable" :checked="miscellConfigDetails.ipSpecifyPermitLoginEnable == true ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Autobind MAC on request access? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="autobindMacOnRequestAccess" :checked="miscellConfigDetails.autobindMacOnRequestAccess == true ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Login Existing users On Social Login? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="loginExistingUserOnSocialLogin" :checked="miscellConfigDetails.loginExistingUserOnSocialLogin == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="miscellConfigDetails.edition=='isp'"> Remove Bounded MAC and IP after User Expires </label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else> Remove Bounded MAC and IP after Voucher Expires </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="removeBoundedIpMac" true-value="yes" false-value="no" v-model="removeBoundedIpMac">
                </div>
              </div>
              <div v-if="removeBoundedIpMac=='yes'">
                <div class="col--sm4"></div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='removeBoundedIpMacOption' value='both' type="radio" name="removeBoundedIpMacOption"> Both
                  <input v-model='removeBoundedIpMacOption' value='mac' type="radio" name="removeBoundedIpMacOption"> Only MAC
                  <input v-model='removeBoundedIpMacOption' value='ip' type="radio" name="removeBoundedIpMacOption"> Only IP
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Don't delete group if users are present </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="groupDeleteUnable" :checked="miscellConfigDetails.groupDeleteUnable == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Accept any password for login </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="randomPasswordLogin" :checked="miscellConfigDetails.randomPasswordLogin == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> While creating router add to all sub zones </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="routerToSubZones" :checked="miscellConfigDetails.routerToSubZones!=undefined && miscellConfigDetails.routerToSubZones == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow cash payments process </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="allowCashPaymentProcess" :checked="miscellConfigDetails.allowCashPaymentProcess!=undefined && miscellConfigDetails.allowCashPaymentProcess == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Don`t delete payment if cash is already processed </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="dontDeleteCashProcessPayment" :checked="miscellConfigDetails.dontDeleteCashProcessPayment!=undefined && miscellConfigDetails.dontDeleteCashProcessPayment == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable OTP verification while creating User </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableOTPWhileCreatingUser" :checked="miscellConfigDetails.enableOTPWhileCreatingUser!=undefined && miscellConfigDetails.enableOTPWhileCreatingUser == 'yes' ? true : false" @click="toggleNumOfDays($event)">
                </div>
              </div>

              <div class="appRow" v-if="numOfDaysShow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Number of days required for OTP after user expired </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="text" name="daysForExpiredUserOTP" v-model="daysForExpiredUserOTP">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable OTP verification while edit User </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableOTPWhileEditUser" :checked="miscellConfigDetails.enableOTPWhileEditUser!=undefined && miscellConfigDetails.enableOTPWhileEditUser == 'yes' ? true : false">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Email verification while creating User </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableEmailWhileCreatingUser" :checked="miscellConfigDetails.enableEmailWhileCreatingUser!=undefined && miscellConfigDetails.enableEmailWhileCreatingUser == 'yes' ? true : false" @click="toggleNumOfEmailDays($event)">
                </div>
              </div>

              <div class="appRow" v-if="numOfEmailDaysShow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Number of days required for Email after user expired </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="text" name="daysForExpiredUserEmail" v-model="daysForExpiredUserEmail">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable EMail verification while edit User </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableEmailWhileEditUser" :checked="miscellConfigDetails.enableEmailWhileEditUser!=undefined && miscellConfigDetails.enableEmailWhileEditUser == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Aadhaar KYC </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableAadhaarkyc" :checked="miscellConfigDetails.enableAadhaarkyc!=undefined && miscellConfigDetails.enableAadhaarkyc == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Aadhaar address stored in  </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <select name="aadharAddressIn" v-model='aadharAddressIn'>
                    <option value="billingAddress" > Billing Address </option>
                    <option value="permanentAddress" > Permanent Address </option>                    
                    <option value="installationAddress" >Installation Address </option>
                   
                  </select>
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable GST Verification </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableGstVerification" :checked="miscellConfigDetails.enableGstVerification!=undefined && miscellConfigDetails.enableGstVerification == 'yes' ? true : false">
                </div>
              </div>

               <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Don't add user Without Aadhaar KYC</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableAddUserwithAadhaarkyc" :checked="miscellConfigDetails.enableAddUserwithAadhaarkyc!=undefined && miscellConfigDetails.enableAddUserwithAadhaarkyc == 'yes' ? true : false">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Maximum Referral limit </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="text" class="input--full" name="maximumReferralLimit" v-model="maximumReferralLimit">
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Account Activation </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <div class="date-input appFormGroup appFormGroup--cozy">
                    <VueCtkDateTimePicker v-model="activDate" name="accountActivationDate" :autoClose="true" :noLabel="true" :format="'DD/MM/YYYY'" :formatted="'ll'" :onlyDate="true" :noClearButton="true" />
                  </div>
                </div>
              </div>

              <div class="appRow" v-if="miscellConfigDetails.edition=='isp' && miscellConfigDetails.permissions.ip_ranges==true">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show only present zones IP ranges? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="showOnlyPresentZoneIpRanges" :checked="miscellConfigDetails.showOnlyPresentZoneIpRanges == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable generate random password </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enablegenerateRandomPassword" :checked="miscellConfigDetails.enablegenerateRandomPassword !=undefined && miscellConfigDetails.enablegenerateRandomPassword == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow" v-if='miscellConfigDetails.accounthardware !="hardware" && miscellConfigDetails.adminLoginAccount == "jazenetworks"'>
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Disable export in all users </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="disableExportInAllUsers" :checked="miscellConfigDetails.disbleExportInAllUsers !=undefined && miscellConfigDetails.disbleExportInAllUsers == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Firebase Cloud Messaging Admin App Server Key </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="text" class="input--full" name="fcmAdminAppServerKey" v-model="fcmAdminAppServerKey">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Firebase Cloud Messaging Customer App Server Key </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="text" class="input--full" name="fcmCustomerAppServerKey" v-model="fcmCustomerAppServerKey">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show Company Name or Not in Ledgers</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="showCompanyNameOrNot" :checked="miscellConfigDetails.showCompanyNameOrNot !=undefined && miscellConfigDetails.showCompanyNameOrNot == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Admin Password Complexity</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="showAdminPasswordComplexity" :checked="miscellConfigDetails.showAdminPasswordComplexity !=undefined && miscellConfigDetails.showAdminPasswordComplexity == 'yes' ? true : false" @click="adminPasswordComplexityDivShow= !adminPasswordComplexityDivShow">
                </div>
              </div>
              <div class="appRow" v-show="adminPasswordComplexityDivShow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary">Admin Password Complexity </label>
                </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="adminPasswordComplexity" value="n" :checked="miscellConfigDetails != undefined && (miscellConfigDetails.adminPasswordComplexity == undefined || miscellConfigDetails.adminPasswordComplexity == 'n') ? true : false">
                      <label for="adminPasswordComplexity"> Numbers </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="adminPasswordComplexity" value="a" :checked="miscellConfigDetails != undefined && miscellConfigDetails.adminPasswordComplexity != undefined && miscellConfigDetails.adminPasswordComplexity == 'a' ? true : false">
                      <label for="adminPasswordComplexity"> Alphabets </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="adminPasswordComplexity" value="na" :checked="miscellConfigDetails != undefined && miscellConfigDetails.adminPasswordComplexity != undefined && miscellConfigDetails.adminPasswordComplexity == 'na' ? true : false">
                      <label for="adminPasswordComplexity"> Combination of numbers and alphabets </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="adminPasswordComplexity" value="nas" :checked="miscellConfigDetails != undefined && miscellConfigDetails.adminPasswordComplexity != undefined && miscellConfigDetails.adminPasswordComplexity == 'nas' ? true : false">
                      <label for="adminPasswordComplexity"> Combination of numbers, alphabets and special characters </label>
                    </div>
                  </div>
                </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <div class="appRow">
                    <div class="col--sm5">
                      <label class="appLabel appLabel--primary appLabel--boxAlign floatRight">Minimum Length</label>
                    </div>
                    <div class="col--sm7 appFormGroup appFormGroup--cozy">
                      <input class="input--full" type="text" name="adminPasswordLength" v-model="adminPasswordLength">
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Reset Admin Password</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableResetAdminPassword" :checked="miscellConfigDetails.enableResetAdminPassword !=undefined && miscellConfigDetails.enableResetAdminPassword == 'yes' ? true : false" @click="enableResetAdminPasswordDivShow= !enableResetAdminPasswordDivShow">
                </div>
              </div>
              <div class="appRow" v-show="enableResetAdminPasswordDivShow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary">Reset Admin Password for Every </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="number" class="input--full" name="resetAdminPasswordForEveryDay" v-model="miscellConfigDetails.resetAdminPasswordForEveryDay">
                </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <select name="resetAdminPasswordForEveryPeriod">
                    <option value="days" v-if="miscellConfigDetails.resetAdminPasswordForEveryPeriod == 'days'" selected> Days </option>
                    <option value="days" v-else> Days </option>
                    <option value="weeks" v-if="miscellConfigDetails.resetAdminPasswordForEveryPeriod == 'weeks'" selected> Weeks </option>
                    <option value="weeks" v-else> Weeks </option>
                    <option value="months" v-if="miscellConfigDetails.resetAdminPasswordForEveryPeriod == 'months'" selected> Months </option>
                    <option value="months" v-else> Months </option>
                  </select>
                </div>
              </div>
              <!-- <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Store old PDF for Invoice</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="storeInvoicePdfInCdn" :checked="miscellConfigDetails.storeInvoicePdfInCdn !=undefined && miscellConfigDetails.storeInvoicePdfInCdn == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Store old PDF for Payments</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="storePaymentPdfInCdn" :checked="miscellConfigDetails.storePaymentPdfInCdn !=undefined && miscellConfigDetails.storePaymentPdfInCdn == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Store old PDF for Credit Notes</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="storeCreditNotePdfInCdn" :checked="miscellConfigDetails.storeCreditNotePdfInCdn !=undefined && miscellConfigDetails.storeCreditNotePdfInCdn == 'yes' ? true : false">
                </div>
              </div> -->
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="miscellConfigDetails.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-if="miscellConfigDetails.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="miscellConfigDetails.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveMiscellaneousConfig($event)"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title" v-if="miscellConfigDetails.edition =='isp'"> Subzone Restrictions </div>
                  <div class="jazeHeader__title" v-else> Subaccount Restrictions </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="miscellConfigDetails.edition =='isp'">Allow/Deny sub-zones to change micellaneous configuration</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change micellaneous configuration</label>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxMiscellaneous" id="switchcheckboxMiscellaneous" :checked="miscellConfigDetails.subAccountsCanNotChangeMiscConfiguration == 'Yes' ? true : false">
                          <label for="switchcheckboxMiscellaneous"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="miscellConfigDetails.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change the miscellaneous configuration.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change the miscellaneous configuration.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="miscellConfigDetails.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'misconfigSetting'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>

  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

import { storeToRefs } from "pinia";
import { uselayoutStore } from '@/store/layoutstore'
import { usesettingsStore } from '@/store/settingsstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore);
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  const removeBoundedIpMac = ref( "no" )
  const removeBoundedIpMacOption = ref( "both" )
  const optionaAllSelect = ref( "all" )
  // const miscellConfigDetails = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const showBandwidthCycle = ref( false )
  const showVendorCycle = ref( false )
  const subzoneType = ref( "" )
  const subZoneModalShow = ref( false )
  const selectedAccVal = ref( null )
  const subAccOptions = ref( [
          {
            id:"selectAll",
            label:"Select All",
            children:[]
          }
        ] )
  const aadharAddressIn = ref( 'billingAddress' )
  const bandwidthCycle = ref( 'monthly' )
  const vendorCycle = ref( 'monthly' )
  const numOfDaysShow = ref( false )
    const numOfEmailDaysShow = ref( false )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const activDate = ref( "" )
  const daysForExpiredUserOTP = ref( "" )
  const maximumReferralLimit = ref( "" )
  const fcmCustomerAppServerKey = ref( "" )
  const fcmAdminAppServerKey = ref( "" )
  const switchcheckboxMiscellaneous = ref( false )
  const dataSetting = ref( [{
            id:"selectAll",
            label:"Select All",
            children:[]
          }] )
  const adminPasswordLength = ref( '4' )
  const adminPassLengthDiv = ref( false )
  const adminPasswordComplexityDivShow = ref( false )
  const enableResetAdminPasswordDivShow = ref( false )
  const pushModal = ref( false )
  const selected = ref( [] )
  // const subAccounts = ref( [] )
  //const selectionType = ref( "" )

  const settingsStore = usesettingsStore()
  const { miscellConfigDetails } = storeToRefs(settingsStore)
  const miscellaneousConfigGetter = computed(() => miscellConfigDetails.value)

  onMounted(() => {
    getConfigDetails();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    miscellConfigDetails.value = miscellaneousConfigGetter.value;
    if (
      miscellConfigDetails.value != undefined &&
      miscellConfigDetails.value.removeBoundedIpMac == "yes"
    ) {
      removeBoundedIpMac.value = "yes";
    }
    if (
      miscellConfigDetails.value != undefined &&
      miscellConfigDetails.value.removeBoundedIpMacOption != "" &&
      miscellConfigDetails.value.removeBoundedIpMacOption != null
    ) {
      removeBoundedIpMacOption.value = miscellConfigDetails.value.removeBoundedIpMacOption;
    }
    if (
      miscellConfigDetails.value.updateBandwidthCycleBasedOnCreatedDate == true
    ) {
      showBandwidthCycle.value = true;
    }
    if (
      miscellConfigDetails.value.updateVendorCycleBasedOnCreatedDate == true
    ) {
      showVendorCycle.value = true;
    }
    if (
      miscellConfigDetails.value.subAccountsCanNotChangeMiscConfiguration ==
      "Yes"
    ) {
      subzoneType.value = "Enabled";
    } else {
      subzoneType.value = "Disabled";
    }
    daysForExpiredUserOTP.value = miscellConfigDetails.value.daysForExpiredUserOTP;
    bandwidthCycle.value = miscellConfigDetails.value.updateBandwidthCycleBasedOnCreatedDatePeriod;
    vendorCycle.value = miscellConfigDetails.value.updateVendorCycleBasedOnCreatedDatePeriod;
    aadharAddressIn.value = miscellConfigDetails.value.aadharAddressIn;
    maximumReferralLimit.value = miscellConfigDetails.value.maximumReferralLimit;
    fcmAdminAppServerKey.value = miscellConfigDetails.value.fcmAdminAppServerKey;
    fcmCustomerAppServerKey.value = miscellConfigDetails.value.fcmCustomerAppServerKey;
    if (
      miscellConfigDetails.value.dataSetting != undefined &&
      miscellConfigDetails.value.dataSetting.length != 0
    ) {
      for (let i = 0; i < miscellConfigDetails.value.dataSetting.length; i++) {
        dataSetting.value[0].children.push({
          id: miscellConfigDetails.value.dataSetting[i].id,
          label: miscellConfigDetails.value.dataSetting[i].label,
        });
      }
    }
    if (
      miscellConfigDetails.value.accountsArr != undefined &&
      miscellConfigDetails.value.accountsArr.length != 0
    ) {
      for (let i = 0; i < miscellConfigDetails.value.accountsArr.length; i++) {
        var deactivated = miscellConfigDetails.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
        subAccOptions.value[0].children.push({
          id: miscellConfigDetails.value.accountsArr[i].id,
          label: miscellConfigDetails.value.accountsArr[i].name + deactivated,
        });
      }
    }
    if (
      miscellConfigDetails.value.enableOTPWhileCreatingUser != undefined &&
      miscellConfigDetails.value.enableOTPWhileCreatingUser == "yes"
    ) {
      numOfDaysShow.value = true;
    }
    if (
      miscellConfigDetails.value.enableEmailWhileCreatingUser != undefined &&
      miscellConfigDetails.value.enableEmailWhileCreatingUser == "yes"
    ) {
      numOfEmailDaysShow.value = true;
    }
    activDate.value = moment(
      miscellConfigDetails.value.accountCreatedDate
    ).format("DD/MM/YYYY");
    if (
      miscellConfigDetails.value.subAccountsCanNotChangeMiscConfiguration ==
      "Yes"
    ) {
      switchcheckboxMiscellaneous.value = true;
    }
    if (
      miscellConfigDetails.value.showAdminPasswordComplexity != undefined && miscellConfigDetails.value.showAdminPasswordComplexity == 'yes'
    ) {
      adminPasswordComplexityDivShow.value = true;
    }
    // Admin Password Complexity
    if (
      miscellConfigDetails.value != undefined &&
      miscellConfigDetails.value.adminPasswordLength !=
        undefined
    ) {
      adminPasswordLength.value = miscellConfigDetails.value.adminPasswordLength;
    }
    // Enable Admin Password
    if (
      miscellConfigDetails.value.enableResetAdminPassword !=
        undefined  && miscellConfigDetails.value.enableResetAdminPassword == 'yes'
    ) {
      enableResetAdminPasswordDivShow.value = true;
    }
    //
  })

  const getConfigDetails = () => {
    if (Object.keys(miscellaneousConfigGetter.value).length == 0) {
      // $store.dispatch("fetchMiscellaneousDetails");
      settingsStore.fetchMiscellaneousDetails();
    } else {
      miscellConfigDetails.value = miscellaneousConfigGetter.value;
      if (
        miscellConfigDetails.value != undefined &&
        miscellConfigDetails.value.removeBoundedIpMac == "yes"
      ) {
        removeBoundedIpMac.value = "yes";
      }
      if (
        miscellConfigDetails.value != undefined &&
        miscellConfigDetails.value.removeBoundedIpMacOption != "" &&
        miscellConfigDetails.value.removeBoundedIpMacOption != null
      ) {
        removeBoundedIpMacOption.value = miscellConfigDetails.value.removeBoundedIpMacOption;
      }
    }
  }
  const toggleNumOfDays = (event) => {
    if (event.target.checked == true) {
      numOfDaysShow.value = true;
    } else {
      numOfDaysShow.value = false;
    }
  }
    const toggleNumOfEmailDays = (event) => {
    if (event.target.checked == true) {
      numOfEmailDaysShow.value = true;
    } else {
      numOfEmailDaysShow.value = false;
    }
  }
  const saveMiscellaneousConfig = (e) => {
    successtoaster.value = false;
    errortoaster.value = false;
    e.preventDefault();
    var form = document.getElementById('miscellConfigForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    // if (
    //   postData.updateBandwidthCycleBasedOnCreatedDatePeriod == undefined ||
    //   postData.updateBandwidthCycleBasedOnCreatedDatePeriod
    // ) {
    //   postData.updateBandwidthCycleBasedOnCreatedDatePeriod = "monthly";
    // }
    // if (
    //   postData.updateVendorCycleBasedOnCreatedDatePeriod == undefined ||
    //   postData.updateVendorCycleBasedOnCreatedDatePeriod
    // ) {
    //   postData.updateVendorCycleBasedOnCreatedDatePeriod = "monthly";
    // }
    if(postData.enableResetAdminPassword != undefined && postData.enableResetAdminPassword == 'on') {
      if(postData.resetAdminPasswordForEveryDay != undefined && postData.resetAdminPasswordForEveryDay == '') {
        errortoaster.value = true;
        errorMessage.value = "Reset Admin Password For Every Day cannot be empty !";
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          6000
        );
        return;
      }
    }
    // $store.dispatch("miscellaneousConfigurationSubmit", postData).then(
    settingsStore.miscellaneousConfigurationSubmit(postData).then(  
      response => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxMiscellaneous.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeMiscConfiguration",
      type: "miscellaneous"
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      response => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const onclickclose = (value) => {
    pushModal.value = value;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

</script>
