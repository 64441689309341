<template>
  <div class="jazeMainPanel " style="height: 100%;">
    <div  class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer ">
        <form ref="billingForm" id="billingForm" class="appForm appForm--cozy is-validation-hidden " :class="formClass">
          <div class="unifiSettingsHeader ">
            <div class="unifiSettingsHeader__title">Billing Settings</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Invoice Total Round Off </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.invoiceTotalRoundOff!=undefined && billingDetailsData.invoiceTotalRoundOff == 'yes'" type="checkbox" name="invoiceTotalRoundOff" checked>
                  <input v-else type="checkbox" name="invoiceTotalRoundOff">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Inverse Balance </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.inverseInvoiceAmount!=undefined && billingDetailsData.inverseInvoiceAmount == 'yes'" type="checkbox" name="inverseInvoiceAmount" checked>
                  <input v-else type="checkbox" name="inverseInvoiceAmount">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use Balance While Plan Change </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.useBalanceChangePlan!=undefined && billingDetailsData.useBalanceChangePlan == 'yes'" type="checkbox" name="useBalanceChangePlan" checked>
                  <input v-else type="checkbox" name="useBalanceChangePlan">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Add users in deactivate packages also </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.addUserInDeactivatePackage!=undefined && billingDetailsData.addUserInDeactivatePackage == 'yes'" type="checkbox" name="addUserInDeactivatePackage" checked>
                  <input v-else type="checkbox" name="addUserInDeactivatePackage">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Update Billing Cycle Based on User Created Date </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.updateBillingCycleBasedOnCreatedDate==true" type="checkbox" name="updateBillingCycleBasedOnCreatedDate" checked @click="showBillingCycle= !showBillingCycle">
                  <input v-else type="checkbox" name="updateBillingCycleBasedOnCreatedDate" @click="showBillingCycle= !showBillingCycle"> &emsp;
                  <select name="updateBillingCycleBasedOnCreatedDatePeriod" v-if="showBillingCycle==true" v-model="billinCycle">
                    <option value="monthly"> monthly </option>
                    <option value="weekly"> weekly </option>
                    <option value="daily"> daily </option>
                  </select>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use no internet page for unpaid users? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.noInternetPage=='yes'" type="checkbox" name="noInternetPage" checked @click="toggleCustomButton">
                  <input v-else type="checkbox" name="noInternetPage" @click="toggleCustomButton"> &nbsp;
                  <button v-if="showCustomButton" class="appMainButton appMainButton--tertiary appMainButton--transparent" type="button" @click="zoneConfigureShow = !zoneConfigureShow">
                    <div class="">Add Attributes</div>
                  </button>
                </div>
              </div>
              <div class="appRow" v-if="framedPoolDiv==true">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use Framed Pool for unpaid users </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input true-value="yes" false-value="no" v-model="useFramedPool" type="checkbox" name="useFramedPool">
                  <!-- <input v-if="billingDetailsData.useFramedPool!=undefined && billingDetailsData.useFramedPool == 'yes'" type="checkbox" name="useFramedPool" checked> -->
                  <!-- <input v-else type="checkbox" name="useFramedPool"> -->
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Recharge to Deactivated package </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input true-value="yes" false-value="no" v-model="changePlanToDeactivated" type="checkbox" name="changePlanToDeactivated">
                  <!-- <input v-else type="checkbox" name="changePlanToDeactivated"> -->
                </div>
              </div>
              <div v-if="changePlanToDeactivated=='yes'">
                <div class="col--sm4"></div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='changePlanToDeactivatedAdmin' value='onlyAdmin' type="radio" name="changePlanToDeactivatedAdmin" > Only Admin
                  <input v-model='changePlanToDeactivatedAdmin' value='bothAdminAndCustomer' type="radio" name="changePlanToDeactivatedAdmin" > Both Admin And Customer
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Recharge to Deactivated OTT Package </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input true-value="yes" false-value="no" v-model="changePlanToDeactivatedOtt" type="checkbox" name="changePlanToDeactivatedOtt">
                </div>
              </div>
              <div v-if="changePlanToDeactivatedOtt=='yes'">
                <div class="col--sm4"></div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='changePlanToDeactivatedOttAdmin' value='onlyAdmin' type="radio" name="changePlanToDeactivatedOttAdmin" > Only Admin
                  <input v-model='changePlanToDeactivatedOttAdmin' value='bothAdminAndCustomer' type="radio" name="changePlanToDeactivatedOttAdmin" > Both Admin And Customer
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Recharge to Deactivated IPTV Package </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input true-value="yes" false-value="no" v-model="changePlanToDeactivatedIptv" type="checkbox" name="changePlanToDeactivatedIptv">
                </div>
              </div>
              <div v-if="changePlanToDeactivatedIptv=='yes'">
                <div class="col--sm4"></div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='changePlanToDeactivatedIptvAdmin' value='onlyAdmin' type="radio" name="changePlanToDeactivatedIptvAdmin" > Only Admin
                  <input v-model='changePlanToDeactivatedIptvAdmin' value='bothAdminAndCustomer' type="radio" name="changePlanToDeactivatedIptvAdmin" > Both Admin And Customer
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Recharge to Deactivated Voice Package </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input true-value="yes" false-value="no" v-model="changePlanToDeactivatedVoice" type="checkbox" name="changePlanToDeactivatedVoice">
                </div>
              </div>
              <div v-if="changePlanToDeactivatedVoice=='yes'">
                <div class="col--sm4"></div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='changePlanToDeactivatedVoiceAdmin' value='onlyAdmin' type="radio" name="changePlanToDeactivatedVoiceAdmin" > Only Admin
                  <input v-model='changePlanToDeactivatedVoiceAdmin' value='bothAdminAndCustomer' type="radio" name="changePlanToDeactivatedVoiceAdmin" > Both Admin And Customer
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Number of times a guest is allowed to access the payment gateway in a day </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="number" name="voucherPayAllowableTimes" v-model="voucherPayAllowableNum">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Do not Allow Internet Access While Payment for Guest Users? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.noInternetForVoucherPay == 'yes'" type="checkbox" name="noInternetForVoucherPay" checked>
                  <input v-else type="checkbox" name="noInternetForVoucherPay">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow Internet for Payment through Customer Portal? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.internetForPaymentCp == 'yes'" type="checkbox" name="internetForPaymentCp" checked>
                  <input v-else type="checkbox" name="internetForPaymentCp">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Redirect New users to Choose Voucher Template? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.redirectVoucherPay == 'yes'" type="checkbox" name="redirectVoucherPay" checked>
                  <input v-else type="checkbox" name="redirectVoucherPay">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Don't generate invoices for suspended users </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if=" billingDetailsData.dontGenerateInvoiceuspended == 'yes'" type="checkbox" name="dontgenerateInvoice" checked>
                  <input v-else type="checkbox" name="dontgenerateInvoice">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Mandatory fields for payment </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.cashPaymentNotes == 'yes'" type="checkbox" name="cashPaymentNotes" checked>
                  <input v-else type="checkbox" name="cashPaymentNotes">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Discount product don't include in tax </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.dontIncludeTaxDiscount == 'yes'" type="checkbox" name="dontIncludeTaxDiscount" checked>
                  <input v-else type="checkbox" name="dontIncludeTaxDiscount">
                </div>
              </div>
              <div class="appRow" v-if="billingDetailsData.edition=='isp'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Packages Create Sub Plans </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.profileCreateSubPlans == 'yes'" type="checkbox" name="profileCreateSubPlans" checked>
                  <input v-else type="checkbox" name="profileCreateSubPlans">
                </div>
              </div>
              <div class="appRow" v-if="billingDetailsData.edition=='isp'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Group Plans </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.groupPlans == 'yes'" type="checkbox" name="groupPlans" checked>
                  <input v-else type="checkbox" name="groupPlans">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Auto Convert Proforma Invoice To Invoice After Payment </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.autoconvertProformaInvoice == 'yes'" type="checkbox" name="autoconvertProformaInvoice" checked>
                  <input v-else type="checkbox" name="autoconvertProformaInvoice">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Override price (applicable for prepaid/postpaid) </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.overridePricePrepaid == 'yes'" type="checkbox" name="overridePricePrepaid" checked>
                  <input v-else type="checkbox" name="overridePricePrepaid">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow change plan to only higher plans </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.changePlanToHigherPlans == 'yes'" type="checkbox" name="changePlanToHigherPlans" checked @click="toggleApplyPlanDiv">
                  <input v-else type="checkbox" name="changePlanToHigherPlans" @click="toggleApplyPlanDiv">
                </div>
              </div>
              <div class="appRow" v-if="applyPlanDiv==true">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Apply to </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="changePlanToHigherPlansintermsof" value="adminOnly" v-if="billingDetailsData.changePlanToHigherPlansMode==undefined || billingDetailsData.changePlanToHigherPlansMode == 'adminOnly'" checked>
                      <input type="radio" name="changePlanToHigherPlansintermsof" value="adminOnly" v-else>
                      <label for="changePlanToHigherPlansintermsof"> Admin </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="changePlanToHigherPlansintermsof" value="customerPortal" v-if="billingDetailsData.changePlanToHigherPlansMode!=undefined && billingDetailsData.changePlanToHigherPlansMode == 'customerPortal'" checked>
                      <input type="radio" name="changePlanToHigherPlansintermsof" value="customerPortal" v-else>
                      <label for="changePlanToHigherPlansintermsof"> Customer Portal </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="changePlanToHigherPlansintermsof" value="both" v-if="billingDetailsData.changePlanToHigherPlansMode!=undefined &&billingDetailsData.changePlanToHigherPlansMode == 'both'" checked>
                      <input type="radio" name="changePlanToHigherPlansintermsof" value="both" v-else>
                      <label for="changePlanToHigherPlansintermsof"> Both </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Calculate carry forward data include with additional FUP data </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.carryForwardIncludeAdditionalFup!=undefined && billingDetailsData.carryForwardIncludeAdditionalFup == 'yes'" type="checkbox" name="carryForwardIncludeAdditionalFup" checked>
                  <input v-else type="checkbox" name="carryForwardIncludeAdditionalFup">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change Plan FUP don`t carry forward </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.changePlanFupDontForward!=undefined && billingDetailsData.changePlanFupDontForward == 'yes'" type="checkbox" name="changePlanFupDontForward" checked>
                  <input v-else type="checkbox" name="changePlanFupDontForward">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Lock invoices </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='lockData' value='no' type="radio" name="lockInvoiceEnable" @click="toggleLockDiv('no')">None
                  <input v-model='lockData' value='yes' type="radio" name="lockInvoiceEnable" @click="toggleLockDiv('yes')"> Based on Days
                  <input v-model='lockData' value='date' type="radio" name="lockInvoiceEnable" @click="toggleLockDiv('date')">Based on Date
                </div>
              </div>
              <div class="appRow" v-if="lockInvoicesDiv">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Lock invoices after number of days from invoice creation. </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="number" name="lockInvoiceDays" v-model="lockInvDays">
                </div>
              </div>
              <div class="appRow" v-if="lockInvoicesDateDiv">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Lock invoices before this date. </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <VueCtkDateTimePicker v-model="invoiceLockDate" name="invoiceLockDate" :autoClose="true" :noLabel="true" :format="'YYYY-MM-DD'" :formatted="'ll'" :onlyDate="true" :noClearButton="true" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Restrict user from termination if security deposit is not returned {{ billingDetailsData.restrictTermination }} </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.restrictTermination == 'yes'" type="checkbox" name="restrictTermination" checked>
                  <input v-else type="checkbox" name="restrictTermination">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Override price should not lessthan package price </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.overridePriceExceedPackagePrice == 'yes'" type="checkbox" name="overridePriceExceedPackagePrice" checked>
                  <input v-else type="checkbox" name="overridePriceExceedPackagePrice">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change Plan apply amount as discount enable</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.changePlanApplyDiscountEnable !=undefined && billingDetailsData.changePlanApplyDiscountEnable == 'yes'" type="checkbox" name="changePlanApplyDiscountEnable" checked>
                  <input v-else type="checkbox" name="changePlanApplyDiscountEnable">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Make auto renewal for expired users</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.makeAUtoRenewalForExpiredUsers !=undefined && billingDetailsData.makeAUtoRenewalForExpiredUsers == 'yes'" type="checkbox" name="makeAUtoRenewalForExpiredUsers" checked>
                  <input v-else type="checkbox" name="makeAUtoRenewalForExpiredUsers">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Upload image on Payment</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.uploadImageOnPayment !=undefined && billingDetailsData.uploadImageOnPayment == 'yes'" type="checkbox" name="uploadImageOnPayment" checked>
                  <input v-else type="checkbox" name="uploadImageOnPayment">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Restrict Change Group </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='restrictChangeGroup' value='no' type="radio" name="restrictChangeGroup">No restrictions
                  <input v-model='restrictChangeGroup' value='yes' type="radio" name="restrictChangeGroup">Business only
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Restrict Change Connection Type </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-model='restrictChangeConnectionType' value='no' type="radio" name="restrictChangeConnectionType">No restrictions
                  <input v-model='restrictChangeConnectionType' value='yes' type="radio" name="restrictChangeConnectionType">Their connection type only
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Calculate StaticIP Charges Based on Billing Cycle</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.staticIpBillingCycle !=undefined && billingDetailsData.staticIpBillingCycle == 'yes'" type="checkbox" name="staticIpBillingCycle" checked>
                  <input v-else type="checkbox" name="staticIpBillingCycle">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Stop Billing </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.stopBilling!=undefined && billingDetailsData.stopBilling == 'yes'" type="checkbox" name="stopBilling" checked>
                  <input v-else type="checkbox" name="stopBilling">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Don't share invoice for users with GST </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.dontShareInvoiceToGstUser !=undefined && billingDetailsData.dontShareInvoiceToGstUser == 'yes'" type="checkbox" name="dontShareInvoiceToGstUser" checked @click="toggleDontShareInvoiceDiv">
                  <input v-else type="checkbox" name="dontShareInvoiceToGstUser" @click="toggleDontShareInvoiceDiv">
                </div>
              </div>
              <div v-if="dontShareInvoiceDiv">
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Applicable From </label>
                  </div>
                  <div class="col--sm4 appFormGroup appFormGroup--cozy">
                    <VueCtkDateTimePicker name="dontShareInvoiceToGstUserApplicableFrom" v-model="dontShareInvoiceToGstUserApplicableFrom" :autoClose="true" :noLabel="true" :format="'YYYY-MM-DD'" :formatted="'ll'" :onlyDate="true" :noClearButton="true" />
                  </div>
                </div>
              </div>
            </div>
             <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Consider Financial Year</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.useAnnualYearForBilling !=undefined && billingDetailsData.useAnnualYearForBilling == 'yes'" type="checkbox" name="useAnnualYearForBilling"  @click="toggleAnnualYearDiv" checked>
                  <input v-else type="checkbox" name="useAnnualYearForBilling" @click="toggleAnnualYearDiv">
                </div>
              </div>
              <div class="appRow" v-if="annualYearDiv==true">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Financial year date </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <select name="financialdate"  v-model="financialdate">
                    <option value="april"> April-March </option>
                    <option value="january"> January-December </option>
                  </select>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> GST number is mandatory for selecting User Type as Business </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.gstNumberMandatory == 'yes'" type="checkbox" name="gstNumberMandatory" checked>
                  <input v-else type="checkbox" name="gstNumberMandatory">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow adding a user without any Signature upload for EKYC Users </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="billingDetailsData.allowUserWithoutSignUpload == 'yes'" type="checkbox" name="allowUserWithoutSignUpload" checked>
                  <input v-else type="checkbox" name="allowUserWithoutSignUpload">
                </div>
              </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="billingDetailsData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-if="billingDetailsData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="billingDetailsData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveBillingSettings"> Save</button>
          </div>
        </div>

      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow" style="display:flex">
      <div class="pos-relative" dialog-window>
        
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex col-12">
                        <label class="jazeLabel jazeLabel--primary col-8" for="notifyViaEmail" v-if="billingDetailsData.edition =='isp'">Allow/Deny sub-zones to change billing settings</label>
                        <label class="jazeLabel jazeLabel--primary col-8" for="notifyViaEmail" v-else>Allow/Deny sub-accounts to change billing settings</label>
                        <div class="col-1"> </div>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxBilling" id="switchcheckboxBilling" :checked="billingDetailsData.subAccountsCanNotChangeBillingSettings == 'Yes' ? 'checked' : ''">
                          <label for="switchcheckboxBilling"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="billingDetailsData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change the billing settings.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change the billing settings.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
      
      </div>
    </div>
    <internetConfigureModal @extend-clicked-zone-detail="onclickclose" :vendors="billingDetailsData.vendorsAll" :editData="billingDetailsData.editData" :billAccountId="billingDetailsData.billAccountId" v-if="zoneConfigureShow"></internetConfigureModal>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="billingDetailsData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'billingSetting'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>

  </div>
</template>

<script setup>
import { ref,toRefs,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import internetConfigureModal from "@/components/Settings/InternetConfigure.vue";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore)
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  const changePlanToDeactivated = ref( "no" )
  const useFramedPool = ref( "no" )
  const changePlanToDeactivatedAdmin = ref( "onlyAdmin" )
  const changePlanToDeactivatedOtt = ref( "no" )
  const changePlanToDeactivatedIptv = ref( "no" )
  const changePlanToDeactivatedVoice = ref( "no" )
  const changePlanToDeactivatedOttAdmin = ref( "onlyAdmin" )
  const changePlanToDeactivatedIptvAdmin = ref( "onlyAdmin" )
  const changePlanToDeactivatedVoiceAdmin = ref( "onlyAdmin" )
  const lockData = ref( "" )
  const restrictChangeGroup = ref( "" )
  const restrictChangeConnectionType = ref("" )
  const lockInvoicesDateDiv = ref( false )
  const dontShareInvoiceDiv = ref(false )
  const annualYearDiv=ref(false)
  const financialdate=ref('april');
  const billingDetailsData = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const errortoaster = ref( false )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const showBillingCycle = ref( false )
  const applyPlanDiv = ref( false )
  const lockInvoicesDiv = ref( false )
  const subZoneModalShow = ref( false )
  const subzoneType = ref( "" )
  const selectedAccVal = ref( null )
  const showCustomButton = ref( false )
  const zoneConfigureShow = ref( false )
  const framedPoolDiv = ref( false )
  const billinCycle = ref( "monthly" )
  const lockInvDays = ref( 0 )
  const voucherPayAllowableNum = ref( "" )
  const switchcheckboxBilling = ref( false )
  const invoiceLockDate = ref( moment() )
  const dontShareInvoiceToGstUserApplicableFrom = ref(moment() )
  const optionaAllSelect = ref( "all" )
  const dataSetting = ref( [{
            id: "selectAll",
            label: "Select All",
            children: []
          }] )
  // const dataSettingValue = ref( null ),
  const pushModal = ref( false )
  const selected = ref( [] )
  // const subAccounts = ref( [] )

  const settingsStore = usesettingsStore()
  const { billingDetails } = storeToRefs(settingsStore)
  const billingSettingsGetter = computed(() => billingDetails.value)

  onMounted(() => {
    getBillingData();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    billingDetailsData.value = billingSettingsGetter.value;
    if(billingDetailsData.value != undefined && billingDetailsData.value.useFramedPool=='yes'){
        useFramedPool.value ='yes';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivated=='yes'){
        changePlanToDeactivated.value='yes';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedAdmin=='bothAdminAndCustomer'){
        changePlanToDeactivatedAdmin.value ='bothAdminAndCustomer';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedOtt=='yes'){
        changePlanToDeactivatedOtt.value ='yes';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedOttAdmin=='bothAdminAndCustomer'){
        changePlanToDeactivatedOttAdmin.value ='bothAdminAndCustomer';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedIptv=='yes'){
        changePlanToDeactivatedIptv.value ='yes';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedIptvAdmin=='bothAdminAndCustomer'){
        changePlanToDeactivatedIptvAdmin.value ='bothAdminAndCustomer';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedVoice=='yes'){
        changePlanToDeactivatedVoice.value ='yes';
    }
    if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedVoiceAdmin=='bothAdminAndCustomer'){
        changePlanToDeactivatedVoiceAdmin.value ='bothAdminAndCustomer';
    }
    lockData.value = billingDetailsData.value.lockInvoiceEnable;
    billinCycle.value = billingDetailsData.value.updateBillingCycleBasedOnCreatedDatePeriod;
    restrictChangeGroup.value = billingDetailsData.value.restrictChangeGroup;
    restrictChangeConnectionType.value = billingDetailsData.value.restrictChangeConnectionType;
    if (billingDetailsData.value.noInternetPage == "yes") {
      showCustomButton.value = true;
      framedPoolDiv.value = true;
    }
    if (billingDetailsData.value.subAccountsCanNotChangeBillingSettings == "Yes") {
      subzoneType.value = "Enabled";
    } else {
      subzoneType.value = "Disabled";
    }
    if (billingDetailsData.value.lockInvoiceEnable == "yes") {
      lockInvoicesDiv.value = true;
    }
    if (billingDetailsData.value.lockInvoiceEnable == "date") {
      lockInvoicesDateDiv.value = true;
    }
    if (billingDetailsData.value.changePlanToHigherPlans == "yes") {
      applyPlanDiv.value = true;
    }
    if (
      billingDetailsData.value.dataSetting != undefined &&
      billingDetailsData.value.dataSetting.length != 0
    ) {
      for (let i = 0; i < billingDetailsData.value.dataSetting.length; i++) {
        dataSetting.value[0].children.push({
          id: billingDetailsData.value.dataSetting[i].id,
          label: billingDetailsData.value.dataSetting[i].label,
        });
      }
    }
    if (billingDetailsData.value.updateBillingCycleBasedOnCreatedDate == true) {
      showBillingCycle.value = true;
    }
    lockInvDays.value = billingDetailsData.value.lockInvoiceDays;
    invoiceLockDate.value = moment(billingDetailsData.value.lockInvoiceDate).format(
      "yyyy-MM-DD"
    );
    voucherPayAllowableNum.value = billingDetailsData.value.voucherPayAllowableTimes;
    if (billingDetailsData.value.subAccountsCanNotChangeBillingSettings == "Yes") {
      switchcheckboxBilling.value = true;
    }
    if (billingDetailsData.value.dontShareInvoiceToGstUser == "yes") {
      dontShareInvoiceDiv.value = true;
    }
    if (billingDetailsData.value.useAnnualYearForBilling == "yes") {
      annualYearDiv.value = true;
    }
    financialdate.value = billingDetailsData.value.financialdate;
    
    if(billingDetailsData.value.dontShareInvoiceToGstUserApplicableFrom != undefined && billingDetailsData.value.dontShareInvoiceToGstUserApplicableFrom != ""){
    dontShareInvoiceToGstUserApplicableFrom.value = moment(billingDetailsData.value.dontShareInvoiceToGstUserApplicableFrom).format(
      "yyyy-MM-DD"
    );
    }
  })

  const getBillingData = () => {
    if (Object.keys(billingSettingsGetter.value).length == 0) {
      // $store.dispatch("fetchBillingDetails");
      settingsStore.fetchBillingDetails();
    } else {
      billingDetailsData.value = billingSettingsGetter.value;
      if(billingDetailsData.value != undefined && billingDetailsData.value.useFramedPool=='yes'){
        useFramedPool.value ='yes';
      }
      if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivated=='yes'){
        changePlanToDeactivated.value='yes';
      }
      if(billingDetailsData.value != undefined && billingDetailsData.value.changePlanToDeactivatedAdmin=='bothAdminAndCustomer'){
        changePlanToDeactivatedAdmin.value ='bothAdminAndCustomer';
      }
    }
  }
  const saveBillingSettings = (e) => {
    successtoaster.value = false;
    errortoaster.value = false;
    e.preventDefault();
    var form = document.getElementById('billingForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    // postData.changePlanToHigherPlansintermsof = changePlanToHigherPlansintermsof.value;
    postData.updateBillingCycleBasedOnCreatedDatePeriod = billinCycle.value;
    postData.lockInvoiceDays = lockInvDays.value;
    if (postData.changePlanToHigherPlansintermsof == undefined) {
      postData.changePlanToHigherPlansintermsof = "adminOnly";
    }
    // $store.dispatch("billingSettingsSubmit", postData).then(
    settingsStore.billingSettingsSubmit(postData).then(  
      response => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const toggleLockDiv = (value) => {
    if (value == "yes") {
      lockInvoicesDiv.value = true;
      lockInvoicesDateDiv.value = false;
    } else if (value == "date") {
      lockInvoicesDateDiv.value = true;
      lockInvoicesDiv.value = false;
    } else {
      lockInvoicesDiv.value = false;
      lockInvoicesDateDiv.value = false;
    }
  }
  const toggleDontShareInvoiceDiv = () => {
    if (event.target.checked == true) {
      dontShareInvoiceDiv.value = true;
    } else {
      dontShareInvoiceDiv.value = false;
    }
  }
  const toggleAnnualYearDiv = () => {
    if (event.target.checked == true) {
      annualYearDiv.value = true;
    } else {
      annualYearDiv.value = false;
    }
  }
  const toggleApplyPlanDiv = () => {
    if (event.target.checked == true) {
      applyPlanDiv.value = true;
    } else {
      applyPlanDiv.value = false;
    }
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxBilling.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeBillingSettings",
      type: "billing"
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      response => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const toggleCustomButton = (event) => {
    if (event.target.checked == true) {
      showCustomButton.value = true;
      framedPoolDiv.value = true;
    } else {
      showCustomButton.value = false;
      framedPoolDiv.value = false;
    }
  }
  const onclickclose = (value) => {
    zoneConfigureShow.value = value;
    pushModal.value = value;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

</script>
