<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="dashConfigForm" id="dashConfigForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Dashboard Configuration</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div v-if="dashConfigDetails.edition == 'isp'" class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Users Count</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" @click='enableChurnedDiv' name="usersCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.usersCount!=undefined && dashConfigDetails.dashboardConfiguration.usersCount == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div v-if="churnedcountconfig">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Churned User From</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy form-group appRow">
                  <input type="text" v-model='churnedCountDays' name="churnedCountDays" /> &emsp;
                  <select class="churned" v-model='churnedCountMethod' name="churnedCountMethod">
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                  </select>
                </div>
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Churned User Duration</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy form-group appRow">
                  <input type="text" v-model='churnedFromCountDays' name="churnedFromCountDays" /> &emsp;
                  <select class="churned" v-model='churnedFromCountMethod' name="churnedFromCountMethod">
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                  </select>
                </div>
              </div>
              <div v-else class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Session Details</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="sessionDetails" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.sessionDetails!=undefined && dashConfigDetails.dashboardConfiguration.sessionDetails == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Online Sessions Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="activeSessionsGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.activeSessionsGraph!=undefined && dashConfigDetails.dashboardConfiguration.activeSessionsGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Nas-wise Active Sessions Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="nasWiseActiveSessionsGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.nasWiseActiveSessionsGraph!=undefined && dashConfigDetails.dashboardConfiguration.nasWiseActiveSessionsGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Online Users Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="activeUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.activeUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.activeUsersGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Active Users Graph Account-wise</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="activeUsersAccountWiseGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.activeUsersAccountWiseGraph!=undefined && dashConfigDetails.dashboardConfiguration.activeUsersAccountWiseGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow" v-if="dashConfigDetails.edition != 'isp'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Data Transfer Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="dataTransferGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.dataTransferGraph!=undefined && dashConfigDetails.dashboardConfiguration.dataTransferGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Pay As You Go Users Count</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="payAsYouGoUsersCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.payAsYouGoUsersCount!=undefined && dashConfigDetails.dashboardConfiguration.payAsYouGoUsersCount == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Tasks Count</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="tasksCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.tasksCount!=undefined && dashConfigDetails.dashboardConfiguration.tasksCount == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">HelpDesk Count</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="helpDeskCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.helpDeskCount!=undefined && dashConfigDetails.dashboardConfiguration.helpDeskCount == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Revenue Calculations</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="RevenueCalculations" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.RevenueCalculations!=undefined && dashConfigDetails.dashboardConfiguration.RevenueCalculations == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Renewal Details</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="renewalDetails" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.renewalDetails!=undefined && dashConfigDetails.dashboardConfiguration.renewalDetails == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Type Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="paymentTypeGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.paymentTypeGraph!=undefined && dashConfigDetails.dashboardConfiguration.paymentTypeGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Collected Account-wise Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="accountWisePaymentAmountGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.accountWisePaymentAmountGraph!=undefined && dashConfigDetails.dashboardConfiguration.accountWisePaymentAmountGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Collected Day-wise Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="dayWisePaymentAmountGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.dayWisePaymentAmountGraph!=undefined && dashConfigDetails.dashboardConfiguration.dayWisePaymentAmountGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Total Users Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="totalUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.totalUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.totalUsersGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">New Registration Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="newRegistrationGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.newRegistrationGraph!=undefined && dashConfigDetails.dashboardConfiguration.newRegistrationGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Renewed Users Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="renewedUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.renewedUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.renewedUsersGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Tickets Status Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="ticketsStatusGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.ticketsStatusGraph!=undefined && dashConfigDetails.dashboardConfiguration.ticketsStatusGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Total and Exipired Users Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="totalAndExpiredUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.totalAndExpiredUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.totalAndExpiredUsersGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Lead Users Status Graph</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="leadUsersStatusGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.leadUsersStatusGraph!=undefined && dashConfigDetails.dashboardConfiguration.leadUsersStatusGraph == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Show Franchise Revenue Details by deafult</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="franchiseRevenueDetailsDefault" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.franchiseRevenueDetailsDefault!=undefined && dashConfigDetails.dashboardConfiguration.franchiseRevenueDetailsDefault == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Overview Stats</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="overviewStats" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.overviewStats!=undefined && dashConfigDetails.dashboardConfiguration.overviewStats == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">To Be Renewed</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="ToBeRenewed" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.invoiceDashboard!=undefined && dashConfigDetails.dashboardConfiguration.ToBeRenewed == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Invoice Dashboard</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="invoiceDashboard" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.invoiceDashboard!=undefined && dashConfigDetails.dashboardConfiguration.invoiceDashboard == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Dashboard</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="paymentDashboard" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.paymentDashboard!=undefined && dashConfigDetails.dashboardConfiguration.paymentDashboard == '1' ? true : false" /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Show Dashboard Details by default</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" id="defaultDashboardDetails1" name="defaultDashboardDetails" value="yes" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.defaultDashboardDetails!=undefined && dashConfigDetails.dashboardConfiguration.defaultDashboardDetails =='1' ? true : false" />
                      <label for="defaultDashboardDetails">Users Count</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" id="defaultDashboardDetails2" name="defaultDashboardDetails" value="no" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.defaultDashboardDetails!=undefined && dashConfigDetails.dashboardConfiguration.defaultDashboardDetails =='0' ? true : false" />
                      <label for="defaultDashboardDetails">Overview Stats</label>
                    </div>
                    <div v-if="showCustomizedDiv" class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" id="defaultCustomizeDashboard" name="defaultDashboardDetails" value="customize" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.defaultDashboardDetails!=undefined && dashConfigDetails.dashboardConfiguration.defaultDashboardDetails =='2' ? true : false" />
                      <label for="defaultDashboardDetails">Customize Dashboard</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Customize your own Dashboard</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="customizeDashboard" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customizeDashboard!=undefined && dashConfigDetails.dashboardConfiguration.customizeDashboard == '1' ? true : false" @click="toggleshow" /> &emsp;
                </div>
              </div>
              <div v-if="showCustomizedDiv">
                <div class="unifiSettingsHeader__title">Customize Dashboard Configuration</div>
                <div v-if="dashConfigDetails.edition == 'isp'" class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Users Count</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_usersCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_usersCount!=undefined && dashConfigDetails.dashboardConfiguration.customize_usersCount == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div v-else class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Session Details</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_sessionDetails" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_sessionDetails!=undefined && dashConfigDetails.dashboardConfiguration.customize_sessionDetails == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Online Sessions Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_activeSessionsGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_activeSessionsGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_activeSessionsGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Nas Wise Active Sessions Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_nasWiseActiveSessionsGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_nasWiseActiveSessionsGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_nasWiseActiveSessionsGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Online Users Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_activeUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_activeUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_activeUsersGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Active Users Accountwise Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_activeUsersAccountWiseGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_activeUsersAccountWiseGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_activeUsersAccountWiseGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow" v-if="dashConfigDetails.edition != 'isp'">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Data Transfer Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_dataTransferGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_dataTransferGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_dataTransferGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Login Type Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_loginTypeGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_loginTypeGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_loginTypeGraph == 'on' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Pay As You Go User Count</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_payAsYouGoUsersCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_payAsYouGoUsersCount!=undefined && dashConfigDetails.dashboardConfiguration.customize_payAsYouGoUsersCount == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Tasks Count</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_tasksCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_tasksCount!=undefined && dashConfigDetails.dashboardConfiguration.customize_tasksCount == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Helpdesk</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_helpDeskCount" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_helpDeskCount!=undefined && dashConfigDetails.dashboardConfiguration.customize_helpDeskCount == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Revenue Calculations</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_RevenueCalculations" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_RevenueCalculations!=undefined && dashConfigDetails.dashboardConfiguration.customize_RevenueCalculations == 'on' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Renewal Details</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_renewalDetails" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_renewalDetails!=undefined && dashConfigDetails.dashboardConfiguration.customize_renewalDetails == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Payment Type Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_paymentTypeGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_paymentTypeGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_paymentTypeGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Accountwise Payment Amount Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_accountWisePaymentAmountGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_accountWisePaymentAmountGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_accountWisePaymentAmountGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Daywise Payment Amount Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_dayWisePaymentAmountGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_dayWisePaymentAmountGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_dayWisePaymentAmountGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Total Users Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_totalUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_totalUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_totalUsersGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> New Registration Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_newRegistrationGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_newRegistrationGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_newRegistrationGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Renewed Users Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_renewedUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_renewedUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_renewedUsersGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Ticket Status Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_ticketsStatusGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_ticketsStatusGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_ticketsStatusGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Total and Expired User Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_totalAndExpiredUsersGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_totalAndExpiredUsersGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_totalAndExpiredUsersGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Lead Users Status Graph</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_leadUsersStatusGraph" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_leadUsersStatusGraph!=undefined && dashConfigDetails.dashboardConfiguration.customize_leadUsersStatusGraph == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">To Be Renewed</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_toBeRenewed" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_toBeRenewed!=undefined && dashConfigDetails.dashboardConfiguration.customize_toBeRenewed == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Invoice Dashboard</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_invoiceDashboard" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_invoiceDashboard!=undefined && dashConfigDetails.dashboardConfiguration.customize_invoiceDashboard == '1' ? true : false" /> &emsp;
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Dashboard</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="customize_paymentDashboard" :checked="dashConfigDetails.dashboardConfiguration!=undefined && dashConfigDetails.dashboardConfiguration.customize_paymentDashboard!=undefined && dashConfigDetails.dashboardConfiguration.customize_paymentDashboard == '1' ? true : false" /> &emsp;
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-if="dashConfigDetails.edition == 'isp'">SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-else>SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="dashConfigDetails.edition == 'isp'">Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else>Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveDashboardConfig">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title">Save</div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="dashConfigDetails.edition =='isp'">Allow/Deny sub-zones to change dashboard configuration</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change dashboard configuration</label>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" name="switchcheckbox" id="switchcheckbox" :checked="dashConfigDetails.subAccountsCanNotChangeDashboard == 'Yes' ? true : false" />
                          <label for="switchcheckbox"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="dashConfigDetails.edition =='isp'">Note: If it is 'ON', sub-zones can not change the dashboard configuration.</div>
                    <div class="appType--micro appType--quiet italicized" v-else>Note: If it is 'ON', sub-accounts can not change the dashboard configuration.</div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <SuccessToaster :successMessage="successMessage" :sucesstoasterShow="successtoaster" v-if="successtoaster"></SuccessToaster>
    <ErrorToaster :errorMessage="errorMessage" :errortoasterShow="errortoaster" v-if="errortoaster"></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="dashConfigDetails.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'dashboardConfig'" :modalType="'subAccountsOnly'"></PushToSubZone>
  </div>
</template>

<script setup>
import { ref,toRefs,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import ToBeRenewed from "@/components/Dashboard/ToBeRenewed.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore)
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  const churnedCountDays = ref( 1 )
  const churnedCountMethod = ref( "months" )
  const churnedcountconfig = ref( false )
  const churnedFromCountMethod = ref( "months" )
  // const dashConfigDetails = ref( [] )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const subZoneModalShow = ref( false )
  const subzoneType = ref( "" )
  const selectedAccVal = ref( null )
  const subAccOptions = ref( [
          {
            id: "selectAll",
            label: "Select All",
            children: [],
          },
        ] )
  const showCustomizedDiv = ref( false )
  const churnedFromCountDays = ref( 1 )
  const pushModal = ref( false )
  const selected = ref( [] )
  // const subAccounts = ref( [] )

  const settingsStore = usesettingsStore()
  const { dashConfigDetails } = storeToRefs(settingsStore)
  const dashboardConfigGetter = computed(() => dashConfigDetails.value)

  onMounted(() => {
    getConfigDetails();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    dashConfigDetails.value = dashboardConfigGetter.value;
    if (
      dashConfigDetails.value.dashboardConfiguration != undefined &&
      dashConfigDetails.value.dashboardConfiguration.usersCount != undefined &&
      dashConfigDetails.value.dashboardConfiguration.usersCount == "1"
    ) {
      churnedcountconfig.value = true;
    }
    if (
      dashConfigDetails.value.countConfiguration != undefined &&
      dashConfigDetails.value.countConfiguration.days != undefined &&
      dashConfigDetails.value.countConfiguration.days != null &&
      dashConfigDetails.value.countConfiguration.days != ""
    ) {
      churnedCountDays.value = dashConfigDetails.value.countConfiguration.days;
    }
    if (
      dashConfigDetails.value.countConfiguration != undefined &&
      dashConfigDetails.value.countConfiguration.value != undefined &&
      dashConfigDetails.value.countConfiguration.value != null &&
      dashConfigDetails.value.countConfiguration.value != ""
    ) {
      churnedCountMethod.value =
        dashConfigDetails.value.countConfiguration.value;
    }
    if (
      dashConfigDetails.value.countConfiguration != undefined &&
      dashConfigDetails.value.countConfiguration.days != undefined &&
      dashConfigDetails.value.countConfiguration.days != null &&
      dashConfigDetails.value.countConfiguration.days != ""
    ) {
      churnedFromCountDays.value =
        dashConfigDetails.value.churnedFromCountSelect.days;
    }
    if (
      dashConfigDetails.value.countConfiguration != undefined &&
      dashConfigDetails.value.countConfiguration.value != undefined &&
      dashConfigDetails.value.countConfiguration.value != null &&
      dashConfigDetails.value.countConfiguration.value != ""
    ) {
      churnedFromCountMethod.value =
        dashConfigDetails.value.churnedFromCountSelect.value;
    }
    if (dashConfigDetails.value.subAccountsCanNotChangeDashboard == "Yes") {
      subzoneType.value = "Enabled";
    } else {
      subzoneType.value = "Disabled";
    }
    if (
      dashConfigDetails.value.dashboardConfiguration != undefined &&
      dashConfigDetails.value.dashboardConfiguration.customizeDashboard !=
        undefined &&
      dashConfigDetails.value.dashboardConfiguration.customizeDashboard == "1"
    ) {
      showCustomizedDiv.value = true;
    }
    if (
      dashConfigDetails.value.accountsArr != undefined &&
      dashConfigDetails.value.accountsArr.length != 0
    ) {
      for (let i = 0; i < dashConfigDetails.value.accountsArr.length; i++) {
        var deactivated = dashConfigDetails.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
        subAccOptions.value[0].children.push({
          id: dashConfigDetails.value.accountsArr[i].id,
          label: dashConfigDetails.value.accountsArr[i].name + deactivated,
        });
      }
    }
  })

  const getConfigDetails = () => {
    if (Object.keys(dashboardConfigGetter.value).length == 0) {
      // $store.dispatch("fetchDashboardDetails");
      settingsStore.fetchDashboardDetails();
    } else {
      dashConfigDetails.value = dashboardConfigGetter.value;
    }
  }
  const toggleshow = (event) => {
    if (event.target.checked == true) {
      showCustomizedDiv.value = true;
    } else {
      showCustomizedDiv.value = false;
    }
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (document.getElementById("switchcheckbox").checked == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeDashbordConfig",
      type: "dashboard",
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      (response) => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const saveDashboardConfig = (e) => {
    successtoaster.value = false;
    errortoaster.value = false;
    e.preventDefault();
    var form = document.getElementById('dashConfigForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    // $store.dispatch("dashboardConfigurationSubmit", postData).then(
    settingsStore.dashboardConfigurationSubmit(postData).then(  
      (response) => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const enableChurnedDiv = (event) => {
    if (event.target.checked) {
      churnedcountconfig.value = true;
    } else {
      churnedcountconfig.value = false;
    }
  }
  const onclickclose = () =>  {
    pushModal.value = false;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

</script>